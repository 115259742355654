import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';

import MetisMenu from 'react-metismenu';

import {NotesNav, PetsNav, SettingsNav, TasksNav, VetNav} from './NavItems';
import {connect} from "react-redux";
import {getNested} from "../../util";
import {tran} from "../../i18n_translations/translate";

class Nav extends Component {
  state = {};

  render() {
    return (
      <Fragment>
        {/*<h5 className="app-sidebar__heading">Pets</h5>*/}
        <MetisMenu content={PetsNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                   classNameStateIcon="pe-7s-angle-down"/>

        {this.renderSelectedPetMenu()}
        {/*{this.renderSettingsMenu()}*/}
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  renderSelectedPetMenu() {
    const selectedPet = this.props.selectedPet;
    if (!selectedPet) return null;

    const name = getNested(selectedPet, ['name'], '');
    return (
      <Fragment>
        <h5 className="app-sidebar__heading">{name}</h5>
        <MetisMenu content={TasksNav.concat(NotesNav).concat(VetNav)} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                   classNameStateIcon="pe-7s-angle-down"/>
        {/*<MetisMenu content={TasksNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""*/}
        {/*           classNameStateIcon="pe-7s-angle-down"/>*/}
        {/*<MetisMenu content={NotesNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""*/}
        {/*           classNameStateIcon="pe-7s-angle-down"/>*/}
        {/*<MetisMenu content={VetNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""*/}
        {/*           classNameStateIcon="pe-7s-angle-down"/>*/}
      </Fragment>
    );
  }

  renderSettingsMenu() {
    return (
      <Fragment>
        <h5 className="app-sidebar__heading">{tran('Settings')}</h5>
        <MetisMenu content={SettingsNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                   classNameStateIcon="pe-7s-angle-down"/>
      </Fragment>
    );
  }
}

// export default withRouter(Nav);

const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
