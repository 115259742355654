import React, {Suspense} from 'react';


class MySuspense extends React.Component {
  render() {
    return (
      <Suspense fallback={
        <div className="loader-container">
          <div className="loader-container-inner">
            <h6 className="mt-5">
              Please wait while we load all the Components examples
              <small>Because this is a demonstration we load at once all the Components examples. This wouldn't happen in a real live app!</small>
            </h6>
          </div>
        </div>
      }>
        {this.props.children}
      </Suspense>
    );
  }
}

export default MySuspense;
