import React from 'react';
import {getNested} from "../../../util";
import {Input} from "reactstrap";
import {STATUS_DONE, STATUS_INCOMPLETE} from "./const";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";


export class DayCompletions extends React.Component {
  render() {
    const petTask = getNested(this.props.rowData, ['pet_task'], {});
    const taskId = getNested(petTask, ['id'], 0);
    const completions = getNested(this.props.rowData, ['completions'], {});
    const day = getNested(this.props, ['field'], '').split('.')[1];
    const dayCompletions = getNested(completions, [day], []);

    const metadata = getNested(this.props.rowData, ['metadata'], {});
    const isDisabled = getNested(metadata, ['disabled'], false);
    return (
      <React.Fragment>
        <div className="task_completions">
          {dayCompletions.map((completionStatus, completionIdx) => {
            return (
              <div key={`completion_${completionIdx}`} className="task_completion">
                <DayCompletionCheckbox
                  disabled={isDisabled}
                  status={completionStatus}
                  onChange={newStatus => {
                    this.onUpdateCompletion({taskId, day, completionIdx, newStatus});
                  }}
                />
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  onUpdateCompletion({taskId, day, completionIdx, newStatus}) {
    if (!this.props.onUpdate) return;
    this.props.onUpdate({taskId, day, completionIdx, newStatus});
  }
}


export class DayCompletionCheckbox extends React.Component {
  render() {
    const isDisabled = this.props.disabled;
    const status = this.props.status;
    return (
      <div
        className={`day_completion_checkbox ${isDisabled ? 'disabled' : ''} status_${status}`}
        onClick={this.onClick}
      >
        {status === STATUS_DONE ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : null}
      </div>
    );
  }

  onClick = () => {
    if (this.props.disabled) return;
    if (!this.props.onChange) return;

    const newStatus = this.props.status === STATUS_INCOMPLETE ? STATUS_DONE : STATUS_INCOMPLETE;
    this.props.onChange(newStatus);
  }
}
