import React, {useEffect, useState} from "react";
import {DataType, kaReducer, SortingMode, Table, useTable} from "ka-table";
import {ActionType} from "ka-table/enums";
import {Button} from "reactstrap";
import {ServerCommunication} from "../../ServerCommunication";
import {getNested} from "../../util";
import moment from "moment/moment";
import {ISO_DATE_FORMAT} from "../forms/DateInput";
import {kaPropsUtils} from "ka-table/utils";
import {connect} from "react-redux";


/**
 * Props:
 * dataUrl: string.
 * columns: list.
 *    ka-table columns.
 * customCells: dict.
 * pageSize: integer.
 * requestId: any.
 * onSelect: function(selectedRow).
 */
function DataTable(props) {
  const pageSize = getNested(props, ['pageSize'], 10);
  const [pageIndex, setPageIndex] = useState(0);
  const [pagesCount, setPagesCount] = useState(10);
  const [selectedData, setSelectedData] = useState(null);
  // const table = useTable();
  const table = useTable({
    // onDispatch: async (action) => {
    //   // if (action.type === ActionType.UpdatePageIndex) {
    //   //   setPageIndex(action.pageIndex);
    //   // }
    // }
  });

  // const [data, setData] = useState([
  //   {
  //     'id': 1,
  //     'clinic_name': 'Leopold',
  //     'doctor_name': 'Ivanov',
  //     'is_home_visit': false,
  //   },
  //   {
  //     'id': 2,
  //     'clinic_name': 'Beethoven',
  //     'doctor_name': 'Petrov',
  //     'is_home_visit': true,
  //   },
  // ]);
  const data = [];
  const [isInitial, setIsInitial] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const showLoading = () => {
    setIsLoading(true);
    dispatch({
      type: ActionType.ShowLoading,
    });
  };

  const hideLoading = () => {
    setIsLoading(false);
    dispatch({
      type: ActionType.HideLoading,
    });
  };

  const tablePropsInit = {
    columns: props.columns,
    data: data,
    rowKeyField: 'id',
    sortingMode: SortingMode.Single,
    paging: {
      enabled: true,
      pageIndex: pageIndex,
      pageSize: pageSize,
      pagesCount: pagesCount,
    },
    loading: {
      enabled: isLoading,
    },
    table: table,
    childComponents: {
      dataRow: {
        elementAttributes: elemProps => {
          const rowId = getNested(elemProps, ['rowData', 'id'], 0);
          const selectedRows = getNested(elemProps, ['selectedRows'], []);
          const isSelected = selectedRows.includes(rowId);
          return {
            onClick: (event, extendedEvent) => {
              if (isSelected) {
                dispatch({
                  type: ActionType.DeselectAllRows,
                });
              } else {
                dispatch({
                  type: ActionType.DeselectAllRows,
                });
                dispatch({
                  type: ActionType.SelectRow,
                  rowKeyValue: extendedEvent.childProps.rowKeyValue,
                  selectedRow: extendedEvent.childProps.rowData,
                });
              }
            },
            onDoubleClick: (e, extendedEvent) => {
              const rowData = getNested(extendedEvent, ['childProps', 'rowData'], {});
              if (props.onDoubleClick) props.onDoubleClick(rowData);
            },
            className: isSelected ? 'selected' : '',
          };
        },
      },
      cellText: {
        content: (cellProps) => {
          const cellFunc = getNested(props.customCells, [cellProps.column.key], null);
          if (cellFunc) {
            const rowData = getNested(cellProps, ['rowData'], {});
            return cellFunc(rowData);
          }
          return null;
          switch (cellProps.column.key) {
            case 'dt':
              const dt = getNested(cellProps, ['rowData', 'dt'], null);
              if (!dt) return '';
              return moment(dt).format('Do of MMM, YYYY');
              return moment(dt).format(ISO_DATE_FORMAT);
          }
        }
      }
    }
  };

  const [tableProps, changeTableProps] = useState(tablePropsInit);

  const dispatch = (action) => { // dispatch has an *action as an argument
    if (action.type === ActionType.SelectRow) {
      const selected = kaPropsUtils.getSelectedData(tableProps);
      const selectedRow = action.selectedRow;
      setSelectedData(selectedRow);
    }

    if (action.type === ActionType.DeselectAllRows) {
      setSelectedData(null);
    }

    if (action.type === ActionType.UpdatePageIndex) {
      dispatch({
        type: ActionType.DeselectAllRows,
      });
      setPageIndex(action.pageIndex);
    }
    // *kaReducer returns new *props according to previous state and *action, and saves new props to the state
    changeTableProps((prevState) => kaReducer(prevState, action));
  };

  const loadData = () => {
    showLoading();
    const params = {
      'paging': {
        'page_index': pageIndex,
        'page_size': pageSize,
        'pages_count': pagesCount,
      },
      'pet': {
        'id': getNested(props.selectedPet, ['id'], 0),
      },
    };
    ServerCommunication.executeCommand(props.dataUrl, params, (respData) => {
      dispatch({
        type: ActionType.UpdateData,
        data: getNested(respData, ['records'], []),
      });
      updatePaging(getNested(respData, ['paging'], {}));
      hideLoading();
    }, (errors, respData) => {
      setErrors(errors);
      hideLoading();
    });
  };

  const updatePaging = paging => {
    const newPagesCount = getNested(paging, ['pages_count'], 0);
    if (newPagesCount > 0) {
      setPagesCount(newPagesCount);
    }
  };

  useEffect(() => {
    if (isInitial) {
      loadData();
      setIsInitial(false);
    }
  });

  useEffect(() => {
    loadData();
  }, [props.requestId]);

  useEffect(() => {
    loadData();
  }, [pageIndex]);

  useEffect(() => {
    dispatch({
      type: ActionType.UpdatePagesCount,
      pagesCount: pagesCount,
    });
  }, [pagesCount]);

  useEffect(() => {
    if (props.onSelect) props.onSelect(selectedData);
  }, [selectedData]);

  return (
    <React.Fragment>
      {errors.length > 0 ? (
        <ul className="errors">
          {errors.map((error, idx) => {
            return <li key={`err_${idx}`}>{getNested(error, ['message'], '')}</li>
          })}
        </ul>
      ) : null}

      <div className="table_01">
        <Table
          {...tableProps}
          dispatch={dispatch}
        />
      </div>

      {/*<Button onClick={() => {*/}
      {/*  setIsLoading(true);*/}
      {/*  dispatch({*/}
      {/*    type: ActionType.ShowLoading,*/}
      {/*  });*/}
      {/*  return;*/}
      {/*  dispatch({*/}
      {/*    type: ActionType.UpdateData,*/}
      {/*    data: [*/}
      {/*      {*/}
      {/*        'id': 3,*/}
      {/*        'clinic_name': 'Leopold',*/}
      {/*        'doctor_name': 'Sidorov',*/}
      {/*        'is_home_visit': false,*/}
      {/*      },*/}
      {/*    ],*/}
      {/*  });*/}
      {/*  dispatch({*/}
      {/*    type: ActionType.ShowLoading,*/}
      {/*    // text: 'Loading...',*/}
      {/*  });*/}
      {/*  // setData([*/}
      {/*  //   {*/}
      {/*  //     'id': 3,*/}
      {/*  //     'clinic_name': 'Leopold',*/}
      {/*  //     'doctor_name': 'Sidorov',*/}
      {/*  //     'is_home_visit': false,*/}
      {/*  //   },*/}
      {/*  // ]);*/}
      {/*}}>Update</Button>*/}
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
