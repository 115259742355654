import React from 'react';
import {Col, Container, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {MyButton} from "../base/MyButton";
import {MyForm} from "../forms/MyForm";
import {TextInput} from "../forms/TextInput";
import {ServerCommunication} from "../../ServerCommunication";
import {getNested, setNested} from "../../util";
import {connect} from "react-redux";
import {HtmlInput} from "../forms/HtmlInput";
import {tran} from "../../i18n_translations/translate";
import ModalEditor from "../table_data/ModalEditor";
import {BooleanInput} from "../forms/BooleanInput";
import {DateTimeInput} from "../forms/DateTimeInput";


class VisitEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <ModalEditor
        {...this.props}
        valuesMap={{
          'dt': ['dt'],
          'is_home_visit': ['is_home_visit'],
          'description': ['description'],
          'title': ['title'],
        }}
        loadUrl="vet/visit/load_visit"
        saveUrl="vet/visit/save_visit"
        renderFormBody={props => {
          return (
            <React.Fragment>
              {/*<Row>*/}
              {/*  <Col md={9}>*/}
              {/*    <DateTimeInput className={"col-lg-3"} name="dt" label={tran('Date')} isClearable {...props} />*/}
              {/*  </Col>*/}
              {/*  <Col md={3}>*/}
              {/*    <FormGroup>*/}
              {/*      <BooleanInput name="is_home_visit" label={tran('Home visit')} {...props} />*/}
              {/*    </FormGroup>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <FormGroup>
                <DateTimeInput className={"col-lg-3"} name="dt" label={tran('Date')} isClearable {...props} />
                <TextInput label={tran('Title')} name="title" {...props} />
                <BooleanInput name="is_home_visit" label={tran('Home visit')} {...props} />
                <HtmlInput name="description" label={tran('Description')} {...props} />
              </FormGroup>
            </React.Fragment>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VisitEditor);
