import React from 'react';
import {Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {MyButton} from "../base/MyButton";
import {MyForm} from "../forms/MyForm";
import {TextInput} from "../forms/TextInput";
import {SelectInput} from "../forms/SelectInput";
import {ServerCommunication} from "../../ServerCommunication";
import {getNested, setNested} from "../../util";
import {IsoDatePicker} from "../base/IsoDatePicker";
import {DateTimeInput} from "../forms/DateTimeInput";
import {tran} from "../../i18n_translations/translate";


/**
 * Props:
 * petId: integer.
 * isOpen: boolean.
 * onSave: function(pet: object).
 * onClose: function().
 */
export class PetEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'state': '',
      'request_id': 0,
      'errors': [],

      'pet': {},
      'metadata': {},
    };
  }

  render() {
    const isProcessing = this.state['state'] === 'processing';
    const editorTitle = this.props.petId === 0 ? tran('New pet') : getNested(this.state['pet'], ['name'], '');
    const speciesOptions = getNested(this.state['metadata'], ['species'], []).map(sp => {
      return {
        'label': tran(getNested(sp, ['name'], '')),
        'value': getNested(sp, ['code'], ''),
      }
    });
    const monthOptions = [
      {label: tran('January'), value: 1},
      {label: tran('February'), value: 2},
      {label: tran('March'), value: 3},
      {label: tran('April'), value: 4},
      {label: tran('May'), value: 5},
      {label: tran('June'), value: 6},
      {label: tran('July'), value: 7},
      {label: tran('August'), value: 8},
      {label: tran('September'), value: 9},
      {label: tran('October'), value: 10},
      {label: tran('November'), value: 11},
      {label: tran('December'), value: 12},
    ];

    const monthValue = getNested(this.state['pet'], ['data', 'birth', 'month'], null);
    const daysInMonth = [4, 6, 9, 11].includes(monthValue) ? 30
      : ([2].includes(monthValue) ? 29 : 31);
    const dayOptions = [...Array(daysInMonth).keys()].map(x => {
      return {
        label: x + 1,
        value: x + 1,
      };
    });

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          // fullscreen={true}
          toggle={this.onClose}
        >
          <ModalHeader toggle={this.onClose}>{editorTitle}</ModalHeader>
          <ModalBody>
            <MyForm
              autocomplete="off"
              disabled={isProcessing}
              onChange={this.onFormChange}
              values={{
                'name': getNested(this.state['pet'], ['name'], ''),
                'species_code': getNested(this.state['pet'], ['species_code'], ''),
                'gender': getNested(this.state['pet'], ['gender'], ''),
                'data.birth.year': getNested(this.state['pet'], ['data', 'birth', 'year'], null),
                'data.birth.month': getNested(this.state['pet'], ['data', 'birth', 'month'], null),
                'data.birth.day': getNested(this.state['pet'], ['data', 'birth', 'day'], null),
              }}
              errors={this.state['errors']}
              render={(props) => {
                return (
                  <React.Fragment>
                    <FormGroup>
                      <TextInput label={tran('Name')} name="name" {...props} />
                    </FormGroup>
                    <FormGroup>
                      <SelectInput
                        label={tran('Species')}
                        name="species_code"
                        isClearable
                        options={speciesOptions}
                        {...props}
                      />
                    </FormGroup>
                    <FormGroup>
                      <SelectInput
                        label={tran('Gender')}
                        name="gender"
                        isClearable
                        options={[
                          {label: tran('Male'), value: 'male'},
                          {label: tran('Female'), value: 'female'},
                        ]}
                        {...props}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <p>{tran('Birth')}</p>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <DateTimeInput
                            label={tran('Year')}
                            name="data.birth.year"
                            showYearPicker
                            isClearable
                            {...props}
                          />
                        </Col>
                        <Col md={5}>
                          <SelectInput
                            label={tran('Month')}
                            name="data.birth.month"
                            isClearable
                            options={monthOptions}
                            {...props}
                          />
                        </Col>
                        <Col md={4}>
                          <SelectInput
                            label={tran('Day')}
                            name="data.birth.day"
                            isClearable
                            options={dayOptions}
                            {...props}
                          />
                        </Col>
                      </Row>

                    </FormGroup>
                  </React.Fragment>
                );
              }}
            />
          </ModalBody>
          <ModalFooter>
            <MyButton color="primary" onClick={this.savePet} disabled={isProcessing}>{tran('Save')}</MyButton>
            <MyButton onClick={this.onClose} disabled={isProcessing}>{tran('Cancel')}</MyButton>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }

  onFormChange = (name, value) => {
    const pet = this.state['pet'];
    const path = name.split('.');
    setNested(pet, path, value);
    // pet[name] = value;
    this.setState({
      'pet': pet,
    });
  }

  loadPet() {
    this.setState({
      'state': 'processing',
      'errors': [],
    });

    ServerCommunication.executeCommand('pets/load_pet', {'pet': {'id': this.props.petId}}, (data, requestId) => {
      const pet = getNested(data, ['pet'], {});
      this.setState({
        'state': '',
        'pet': pet,
        'metadata': getNested(data, ['metadata'], {}),
      });
    }, (errors, data, requestId) => {
      this.setState({
        'state': '',
        'errors': errors,
      });
    });
  }

  savePet = () => {
    this.setState({
      'state': 'processing',
      'errors': [],
    });

    const params = {
      'pet': this.state['pet'],
    };
    ServerCommunication.executeCommand('pets/save_pet', params, (data, requestId) => {
      this.setState({
        'state': '',
      });
      this.onSavePet();
    }, (errors, data, requestId) => {
      this.setState({
        'state': '',
        'errors': errors,
      });
    });
  }

  onSavePet = () => {
    if (!this.props.onSave) return;
    this.props.onSave();
  }

  onClose = () => {
    if (!this.props.onClose) return;
    this.props.onClose();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.loadPet();
    }
  }
}
