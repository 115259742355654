import React from 'react';
import {FormFeedback, Input, Label} from "reactstrap";
import {MyFormField} from "./MyFormField";
import Select from "react-select";
import {getNested} from "../../util";


export class SelectInput extends React.Component {
  render() {
    return (
      <>
        <MyFormField
          {...this.props}
          render={props => {
            return <Select
              {...props}
              isDisabled={props.disabled}
              options={props.options}
              onChange={this.onChange}
              value={this.getCurrentValue(props)}
              isClearable={props.isClearable}
            />;
          }}
        />
      </>
    );
  }

  getCurrentValue = (props) => {
    let result = null;
    this.props.options.some(option => {
      const val = getNested(option, ['value'], '');
      if (val === props.value) {
        result = option;
        return true;
      }
    });
    return result;
  }

  onChange = (value) => {
    const val = getNested(value, ['value'], null);
    this.props.onChangeField(this.props.name, val);
  }
}
