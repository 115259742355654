import React from 'react';
import {Input} from "reactstrap";
import {MyFormField} from "./MyFormField";


export class TextInput extends React.Component {
  render() {
    return (
      <>
        <MyFormField {...this.props}
          render={props => {
            return <Input type="text" value={props.value} onChange={this.onChange} disabled={props.disabled} {...props} />
          }}
        />
      </>
    );
  }

  onChange=(evt) => {
    this.props.onChangeField(this.props.name, evt.target.value);
  }
}
