import React from 'react';
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {getNested} from "../../util";
import {setSelectedPet} from "../../reducers/PetOptions";
import {connect} from "react-redux";
import {tran} from "../../i18n_translations/translate";
import {Requester} from "./Requester";
import {MyButton} from "./MyButton";
import TaskEditor from "../tasks/TaskEditor";


/**
 * Props:
 * command: string.
 * params: object.
 * renderWidget: function(props).
 *    props: {
 *      row: object,
 *      onUpdate: function(row),
 *    }
 * renderNewRowEditor(props).
 *    props: {
 *      isOpen: boolean,
 *      onSave: function(row),
 *      onClose: function(),
 *    }
 */
class EditableWidgetsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'is_editor_open': false,
      'request_id': 0,
    };
  }

  render() {
    return (
      <React.Fragment>
        {this.renderControls()}

        <Requester
          command={this.props.command}
          params={this.props.params}
          requestId={this.state['request_id']}
          renderData={data => {
            const records = getNested(data, ['records'], []);
            return (
              <React.Fragment>
                <Row>
                  {records.map((record, idx) => {
                    return (
                      <Col key={`widget_${idx}`} md={12}>
                        {this.props.renderWidget({
                          row: record,
                          onUpdate: this.onUpdateRow,
                        })}
                      </Col>
                    );
                  })}
                </Row>
              </React.Fragment>
            );
          }}
        />
      </React.Fragment>
    );
  }

  onUpdateRow = (row) => {
    this.setState({
      'request_id': this.state['request_id'] + 1,
    });
  }

  renderControls() {
    return (
      <React.Fragment>
        {this.props.renderNewRowEditor ? (
          <React.Fragment>
            <div>
              {/*<Card className="main-card mb-3">*/}
              {/*  <CardBody>*/}
              <MyButton color="primary" onClick={this.onClickAddRow}>{tran('New task')}...</MyButton>
              {/*</CardBody>*/}
              {/*</Card>*/}
            </div>

            {this.props.renderNewRowEditor({
              isOpen: this.state['is_editor_open'],
              onSave: this.onSaveRow,
              onClose: () => {
                this.setState({
                  'is_editor_open': false,
                })
              },
            })}
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }

  onSaveRow = (row) => {
    this.setState({
      'is_editor_open': false,
      'request_id': this.state['request_id'] + 1,
    });
  }

  onClickAddRow = () => {
    this.setState({
      'is_editor_open': true,
      'task_id': 0,
    });
  }
}

const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({
  setSelectedPet: selectedPet => dispatch(setSelectedPet(selectedPet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditableWidgetsList);
