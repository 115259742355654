import {Route, Redirect} from "react-router-dom";
import React, {Suspense, lazy, Fragment} from 'react';

import {
  ToastContainer,
} from 'react-toastify';
import MySuspense from "../../components/base/MySuspense";
import {PetsListPage} from "../../Pages/Pets/PetsListPage";
import {TasksListPage} from "../../Pages/Tasks/TasksListPage";
import TaskCalendarPage from "../../Pages/Tasks/TaskCalendarPage";
import TodayTasksPage from "../../Pages/Tasks/TodayTasksPage";
import {NotesListPage} from "../../Pages/Notes/NotesListPage";
import {PetTasksListPage} from "../../Pages/PetTasks/PetTasksListPage";
import {VetVisitsPage} from "../../Pages/Vet/VetVisitsPage";

const Dashboards = lazy(() => import('../../DemoPages/Dashboards'));

const Widgets = lazy(() => import('../../DemoPages/Widgets'));
const Elements = lazy(() => import('../../DemoPages/Elements'));
const Components = lazy(() => import('../../DemoPages/Components'));
const Charts = lazy(() => import('../../DemoPages/Charts'));
const Forms = lazy(() => import('../../DemoPages/Forms'));
const Tables = lazy(() => import('../../DemoPages/Tables'));

const AppMain = () => {

  return (
    <Fragment>

      {/* components */}

      <MySuspense>
        <Route path="/pets/list" component={PetsListPage}/>
        <Route path="/tasks/today" component={TodayTasksPage}/>
        <Route path="/tasks/calendar" component={TaskCalendarPage}/>
        <Route path="/tasks/list" component={PetTasksListPage}/>
        <Route path="/notes/list" component={NotesListPage}/>
        <Route path="/vet/visits/list" component={VetVisitsPage}/>
        <Route path="/settings/tasks/list" component={TasksListPage}/>
      </MySuspense>

      <Route exact path="/" render={() => (
        <Redirect to="/dashboards/basic"/>
      )}/>
      <ToastContainer/>
    </Fragment>
  )
};

export default AppMain;
