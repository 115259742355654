import React, {Fragment} from 'react';

import {
  DropdownToggle, DropdownMenu,
  Nav, Button, NavItem, NavLink,
  UncontrolledTooltip, UncontrolledButtonDropdown
} from 'reactstrap';

import {
  toast,
  Bounce
} from 'react-toastify';


import {
  faCalendarAlt,
  faAngleDown

} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import 'react-toastify/dist/ReactToastify.css';

import avatar1 from '../../../assets/utils/images/avatars/1.jpg';
import {connect} from "react-redux";
import {logOut} from "../../../common/funcs";
import {getNested} from "../../../util";
import {SpeciesGender} from "../../../components/pets/SpeciesGender";
import {tran} from "../../../i18n_translations/translate";

class UserBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };

  }

  render() {
    const selectedPet = this.props.selectedPet;

    return (
      <Fragment>
        {/*{this.renderSelectedPet()}*/}

        <div className="header-btn-lg pe-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left me-3 header-user-info">
                <div className="widget-heading">
                  {this.props.username}
                </div>
              </div>
              <div className="widget-content-left">
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="link" className="p-0">
                    {/*<img width={42} className="rounded-circle" src={avatar1} alt=""/>*/}
                    <FontAwesomeIcon className="ms-2 opacity-8" icon={faAngleDown}/>
                  </DropdownToggle>
                  <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                    <Nav vertical>
                      <NavItem className="nav-item-header">
                        {tran('My Account')}
                      </NavItem>
                      <NavItem>
                        <NavLink href="#" onClick={this.logOut}>
                          {tran('Log out')}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  // render() {
  //
  //     return (
  //         <Fragment>
  //             <div className="header-btn-lg pe-0">
  //                 <div className="widget-content p-0">
  //                     <div className="widget-content-wrapper">
  //                         <div className="widget-content-left">
  //                             <UncontrolledButtonDropdown>
  //                                 <DropdownToggle color="link" className="p-0">
  //                                     <img width={42} className="rounded-circle" src={avatar1} alt=""/>
  //                                     <FontAwesomeIcon className="ms-2 opacity-8" icon={faAngleDown}/>
  //                                 </DropdownToggle>
  //                                 <DropdownMenu end className="rm-pointers dropdown-menu-lg">
  //                                     <Nav vertical>
  //                                         <NavItem className="nav-item-header">
  //                                             Activity
  //                                         </NavItem>
  //                                         <NavItem>
  //                                             <NavLink href="#">
  //                                                 Chat
  //                                                 <div className="ms-auto badge bg-pill bg-info">8</div>
  //                                             </NavLink>
  //                                         </NavItem>
  //                                         <NavItem>
  //                                             <NavLink href="#">Recover Password</NavLink>
  //                                         </NavItem>
  //                                         <NavItem className="nav-item-header">
  //                                             My Account
  //                                         </NavItem>
  //                                         <NavItem>
  //                                             <NavLink href="#">
  //                                                 Settings
  //                                                 <div className="ms-auto badge bg-success">New</div>
  //                                             </NavLink>
  //                                         </NavItem>
  //                                         <NavItem>
  //                                             <NavLink href="#">
  //                                                 Messages
  //                                                 <div className="ms-auto badge bg-warning">512</div>
  //                                             </NavLink>
  //                                         </NavItem>
  //                                         <NavItem>
  //                                             <NavLink href="#">
  //                                                 Logs
  //                                             </NavLink>
  //                                         </NavItem>
  //                                     </Nav>
  //                                 </DropdownMenu>
  //                             </UncontrolledButtonDropdown>
  //                         </div>
  //                         <div className="widget-content-left  ms-3 header-user-info">
  //                             <div className="widget-heading">
  //                                 Alina Mclourd
  //                             </div>
  //                             <div className="widget-subheading">
  //                                 VP People Manager
  //                             </div>
  //                         </div>
  //
  //                         <div className="widget-content-right header-user-info ms-3">
  //                             <Button className="btn-shadow p-1" size="sm" onClick={this.notify2} color="info"
  //                                     id="Tooltip-1">
  //                                 <FontAwesomeIcon className="me-2 ms-2" icon={faCalendarAlt}/>
  //                             </Button>
  //                             <UncontrolledTooltip placement="bottom" target={'Tooltip-1'}>
  //                                 Click for Toastify Notifications!
  //                             </UncontrolledTooltip>
  //                         </div>
  //                     </div>
  //                 </div>
  //             </div>
  //         </Fragment>
  //     )
  // }

  logOut = () => {
    logOut(this.props.dispatch);
  }

  renderSelectedPet = () => {
    const selectedPet = this.props.selectedPet;
    if (!selectedPet) return null;

    const name = getNested(selectedPet, ['name'], '');

    return (
      <div className="header-btn-lg pe-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left me-3 header-user-info">
              <div className="widget-heading">
                {name}
              </div>
              <div className="widget-subheading">
                <SpeciesGender pet={selectedPet} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  username: state.UserInfo.username,
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBox);
