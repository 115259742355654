import {getNested} from "../../../util";
import React from "react";
import {tran} from "../../../i18n_translations/translate";

export function TaskDescription(props) {
  const task = props.task;
  const name = getNested(task, ['name'], '');
  const description = getNested(task, ['description'], '');
  // const period = getNested(task, ['data', 'period'], '');
  // const timesPerPeriod = getNested(task, ['data', 'times_per_period'], 0);
  // const firstDay = getNested(task, ['data', 'first_day'], '');
  // const lastDay = getNested(task, ['data', 'last_day'], '');
  return (
    <div className="task_description">
      <p className="name">{name}</p>
      <p className="description">{description}</p>
      {/*<p className="metainfo">{timesPerPeriod} {tran('times', {count: timesPerPeriod})} {tran(`a ${period}`)}*/}
      {/*  {firstDay ? <><br/><span>{tran('Starts')}: {firstDay}</span></> : null}*/}
      {/*  {lastDay ? <><br/><span>{tran('Ends')}: {lastDay}</span></> : null}*/}
      {/*</p>*/}
    </div>
  );
}
