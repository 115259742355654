import React from 'react';
import {getNested} from "../../util";
import {ServerCommunication} from "../../ServerCommunication";
import {Alert} from "reactstrap";


/**
 * Props:
 * command: string.
 * params: object. Optional.
 * renderData: function(data).
 * requestId: any.
 */
export class Requester extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'state': 'initial',
      'request_id': 0,
      'data': {},
      'is_mounted': false,
    };
  }

  componentDidMount() {
    this.incrementRequestId();
    this.setState({
      'is_mounted': true,
    });
  }

  componentWillUnmount() {
    this.setState({
      'is_mounted': false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const prevReqId = prevState['request_id'];
    const reqId = this.state['request_id'];
    if (reqId !== prevReqId) {
      this.requestData();
      return;
    }

    const prevPropRequestId = prevProps.requestId;
    const propRequestId = this.props.requestId;
    if (prevPropRequestId !== propRequestId) {
      this.incrementRequestId();
    }
  }

  incrementRequestId() {
    this.setState({
      'request_id': this.state['request_id'] + 1,
      'state': 'loading',
    });
  }

  requestData() {
    const requestId = this.state['request_id'];

    ServerCommunication.executeCommand(this.props.command, this.props.params, respData => {
      this.processResponse(respData, requestId, 'data');
    }, respData => {
      this.processResponse(respData, requestId, 'errors');
    });
  }

  processResponse(respData, requestId, responseType) {
    if (!this.state['is_mounted']) return null;
    if (requestId !== this.state['request_id']) return;

    this.setState({
      'state': responseType === 'data' ? 'ready' : 'errors',
      'data': respData,
    });
  }

  render() {
    if (this.state['state'] === 'loading') {
      return this.renderLoading();
    }
    if (this.state['state'] === 'ready') {
      return this.renderData();
    }
    if (this.state['state'] === 'errors') {
      return this.renderErrors();
    }

    return null;
  }

  renderLoading() {
    return <span>Loading...</span>;
  }

  renderData() {
    if (!this.props.renderData) return null;
    return this.props.renderData(this.state['data']);
  }

  renderErrors() {
    const errors = getNested(this.state['data'], [], []);
    return (
      <React.Fragment>
        <Alert color="danger">
          <p>ERROR: could not retrieve data</p>
          <ul className="errors">
            {errors.map((err, idx) => {
              return <li key={`error_${idx}`}>{getNested(err, ['message'], '')}</li>;
            })}
          </ul>
        </Alert>
      </React.Fragment>
    );
  }
}
