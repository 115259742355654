import {MyFormField} from "./MyFormField";
import {Input} from "reactstrap";
import React from "react";
import {IsoDatePicker} from "../base/IsoDatePicker";

export class DateTimeInput extends React.Component {
  render() {
    return (
      <>
        <MyFormField
          {...this.props}
          render={props => {
            // const valueProps = this.props.selectsRange ?
            return (
              <IsoDatePicker
                {...props}
                selected={props.value}
                onChange={this.onChange}
              />
            );
          }}
        />
      </>
    );
  }

  onChange = (newValue) => {
    if (this.props.showYearPicker) {
      if (!!newValue) {
        newValue = newValue.substring(0, 4);
      }
    }
    this.props.onChangeField(this.props.name, newValue);
 }
}
