import React from 'react';
import {Editor} from "react-draft-wysiwyg";
import {convertFromHTML, ContentState, EditorState, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


export class HtmlEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'editor_state': EditorState.createEmpty(),
    };
  }

  render() {
    const editorState = this.state['editor_state'];
    return (
      <>
        <Editor
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link',
              'emoji', 'remove', 'history'],
          }}
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
        />
      </>
    );
  }

  componentDidMount() {
    this.setState({
      'editor_state': this.getEditorStateFromValue(this.props.value),
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const currentContent = draftToHtml(convertToRaw(this.state['editor_state'].getCurrentContent()));

    // if (this.props.value && (this.props.value !== prevProps.value) && (this.props.value !== currentContent)) {
    if ((this.props.value !== prevProps.value) && (this.props.value !== currentContent)) {
      const newEditorState = this.getEditorStateFromValue(this.props.value);
      this.setState({
        'editor_state': newEditorState,
      });
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      'editor_state': editorState,
    });

    if (!this.props.onChange) return;
    this.props.onChange(this.getValueFromEditorState(editorState));
  };

  getEditorStateFromValue = (value) => {
    if (!value) return EditorState.createEmpty();
    const contentBlock = htmlToDraft(value);
    if (!contentBlock) return EditorState.createEmpty();

    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }

  getValueFromEditorState = (editorState) => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  }
}
