import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import DataTable from "./DataTable";
import {tran} from "../../i18n_translations/translate";
import {ServerCommunication} from "../../ServerCommunication";
import {connect} from "react-redux";
import {getNested, setNested} from "../../util";
import {MyButton} from "../base/MyButton";
import {Errors} from "../base/Errors";


/**
 * Props:
 * columns: list.
 *    ka-table columns.
 * dataUrl: string.
 * deleteUrl: string.
 * customCells: dict.
 * pageSize: integer.
 */
class EditableDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'is_editor_open': false,
      'record': {},
      'selected_record': null,
      'request_id': 1,

      'confirmation': {
        'is_open': false,
        'is_processing': false,
        'text': '',
        'on_confirm': () => {},
        'errors': ['asdfa', 'f243'],
      },
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="">
          <Button onClick={() => {
            this.setState({
              'record': {},
              'is_editor_open': true,
            });
          }}>{tran('New...')}</Button>
          {' '}
          <Button color="danger" disabled={!this.state['selected_record']} onClick={() => {
            this.showConfirmation('Delete record?', 'Do you want to delete the selected record?', () => {
              this.enableConfirmationProcessing();
              this.deleteRecord(this.state['selected_record'], (respData) => {
                this.closeConfirmation();
                this.setState({
                  'request_id': this.state['request_id'] + 1,
                });
              }, (errors) => {
                this.setConfirmationErrors(errors);
                this.disableConfirmationProcessing();
              });
            });
          }}>{tran('Delete...')}</Button>
        </div>

        <DataTable
          requestId={this.state['request_id']}
          dataUrl={this.props.dataUrl}
          columns={this.props.columns}
          customCells={this.props.customCells}
          pageSize={this.props.pageSize}
          onDoubleClick={row => {
            this.setState({
              'record': row,
              'is_editor_open': true,
            });
          }}
          onSelect={this.onSelectRecord}
        />

        {this.renderConfirmation()}

        {this.renderEditor()}
      </React.Fragment>
    );
  }

  renderConfirmation = () => {
    const confirmation = this.state['confirmation'];
    const isDisabled = getNested(confirmation, ['is_processing'], false);
    const errors = getNested(confirmation, ['errors'], []);
    return (
      <Modal
        isOpen={confirmation['is_open']}
        toggle={isDisabled ? null : this.closeConfirmation}
      >
        <ModalHeader>{confirmation['title']}</ModalHeader>
        <ModalBody>{confirmation['body']}</ModalBody>
        <ModalFooter>
          <MyButton disabled={isDisabled} color="danger" onClick={confirmation['on_confirm']}>{tran('Yes')}</MyButton>
          <MyButton disabled={isDisabled} onClick={this.closeConfirmation}>{tran('No')}</MyButton>
        </ModalFooter>

        {errors.length ? (
          <ModalFooter style={{justifyContent: 'flex-start'}}>
            <Errors errors={errors} />
          </ModalFooter>
        ) : null}
      </Modal>
    );
  }

  deleteRecord = (record, onSuccess, onFail) => {
    const params = {
      'record': record,
      'pet': {
        'id': getNested(this.props.selectedPet, ['id'], 0),
      },
    }
    ServerCommunication.executeCommand(this.props.deleteUrl, params, (respData) => {
      onSuccess(respData);
    }, (errors) => {
      onFail(errors);
    });
  }

  showConfirmation = (title, body, onYes) => {
    this.setState({
      'confirmation': {
        'is_open': true,
        'is_processing': false,
        'title': title,
        'body': body,
        'on_confirm': onYes,
        'errors': [],
      },
    });
  }

  closeConfirmation = () => {
    this.setState({
      'confirmation': {
        'is_open': false,
        'is_processing': false,
        'title': '',
        'body': '',
        'on_confirm': () => {},
        'errors': [],
      },
    });
  }

  enableConfirmationProcessing = () => {
    const confirmation = this.state['confirmation'];
    confirmation['is_processing'] = true;
    this.setState({
      'confirmation': confirmation,
    });
  }

  setConfirmationErrors = (errors) => {
    const confirmation = this.state['confirmation'];
    confirmation['errors'] = errors;
    this.setState({
      'confirmation': confirmation,
    });
  }

  disableConfirmationProcessing = () => {
    const confirmation = this.state['confirmation'];
    confirmation['is_processing'] = false;
    this.setState({
      'confirmation': confirmation,
    });
  }

  onSelectRecord = (rowData) => {
    this.setState({
      'selected_record': rowData,
    });
  }

  onCloseEditor = () => {
    this.setState({
      'is_editor_open': false,
    });
  }

  onSaveRecord = record => {
    this.setState({
      'is_editor_open': false,
      'request_id': this.state['request_id'] + 1,
    });
  }

  renderEditor() {
    return this.props.renderEditor({
      isOpen: this.state['is_editor_open'],
      onClose: this.onCloseEditor,
      record: this.state['record'],
      onSave: this.onSaveRecord,
    });
  }
}

const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(EditableDataTable);
