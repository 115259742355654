import {ServerCommunication} from "../ServerCommunication";
import {getNested} from "../util";
import {setIsAuthenticated, setUserInfo} from "../reducers/UserInfo";
import moment from "moment";
import {ISO_DATE_FORMAT} from "../components/forms/DateInput";

export function getUserConfiguration(dispatch) {
  ServerCommunication.executeCommand('auth/get_user_configuration', {}, (respData) => {
    const authStatus = getNested(respData, ['auth_status'], '');
    if (authStatus !== 'authenticated') {
      dispatch(setUserInfo({
        isAuthenticated: false,
      }));
      return;
    }

    const username = getNested(respData, ['username'], '');
    dispatch(setUserInfo({
      isAuthenticated: true,
      username: username,
    }));
  }, (errors, respData) => {
    console.log('failed');
    console.log(errors);
    console.log(respData);
  })
}


export function logOut(dispatch) {
  localStorage.removeItem('jwt');
  dispatch(setUserInfo({
    isAuthenticated: false,
  }));

  ServerCommunication.executeCommand('auth/logout', {}, (respData) => {
    console.log('success');
    console.log(respData);
  }, (errors, respData) => {
    console.log('failed');
    console.log(errors);
    console.log(respData);
  });
}


export function getTodayDate() {
  return moment(new Date()).format(ISO_DATE_FORMAT);
}
