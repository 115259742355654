import React from 'react';
import {MyFormField} from "./MyFormField";
import {HtmlEditor} from "../base/HtmlEditor";


export class HtmlInput extends React.Component {
  render() {
    return (
      <>
        <MyFormField
          {...this.props}
          render={props => {
            return <HtmlEditor
              value={props.value}
              onChange={this.onChange}
              disabled={props.disabled}
              {...props}
            />
          }}
        />
      </>
    );
  }

  onChange = (value) => {
    this.props.onChangeField(this.props.name, value);
  }
}
