import React, {useState} from 'react';
import {connect} from "react-redux";

import {setSelectedPet} from "../../reducers/PetOptions";
import {Button} from "reactstrap";
import {DataTable} from "../table_data/DataTable";
import EditableDataTable from "../table_data/EditableDataTable";
import VisitEditor from "./VisitEditor";
import {DataType} from "ka-table";
import {Redirect} from "react-router-dom";
import {getNested} from "../../util";
import moment from "moment";
import {DayCompletionCheckbox} from "../tasks/task_calendar/DayCompletions";
import {STATUS_DONE} from "../tasks/task_calendar/const";
import {tran} from "../../i18n_translations/translate";


function VisitsList(props) {
  if (props.selectedPet === null) return <Redirect to={'/pets/list'} />
  return (
    <React.Fragment>
      <EditableDataTable
        pageSize={10}
        dataUrl="vet/visit/get_list"
        deleteUrl="vet/visit/delete"
        columns={[
          {key: 'dt', title: tran('Visit date'), dataType: DataType.Date},
          {key: 'title', title: tran('Title'), dataType: DataType.String},
          // {key: 'clinic_name', title: tran('Clinic'), dataType: DataType.String},
          // {key: 'doctor_name', title: tran('Doctor'), dataType: DataType.String},
          {key: 'is_home_visit', title: tran('At home'), dataType: DataType.Boolean},
        ]}
        renderEditor={props => {
          return <VisitEditor {...props}/>;
        }}
        customCells={{
          'dt': rowData => {
            const dt = getNested(rowData, ['dt'], null);
            if (!dt) return '';
            return moment(dt).format('Do of MMM, YYYY');
          },
          'is_home_visit': rowData => {
            if (getNested(rowData, ['is_home_visit'], false))
              return <DayCompletionCheckbox status={STATUS_DONE} />;
            return ' ';
          },
        }}
      />
    </React.Fragment>
  );
}


const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({
  setSelectedPet: selectedPet => dispatch(setSelectedPet(selectedPet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VisitsList);
