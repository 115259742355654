import React from 'react';
import {FormGroup} from "reactstrap";


/**
 * Props:
 * hidden: boolean.
 */
export function MyFormGroup(props) {
  return <FormGroup {...props} />;
}