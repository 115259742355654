import {getNested} from "./util";
import {AJAX_URL} from "./config";


export class ServerCommunication {
  static AJAX_URL = AJAX_URL;

  /**
   * Sends a command to the server.
   * @param {string} command
   * @param {object} params
   * @param {function(data, requestId)} onSuccess
   * @param {function(errors, data, requestId)} onFail
   * @param {boolean} refreshPageOnLoginError
   */
  static executeCommand(command, params, onSuccess, onFail, refreshPageOnLoginError) {
    if (refreshPageOnLoginError === undefined) refreshPageOnLoginError = true;

    const requestId = Date.now();
    const req = {
      'command': command,
      'request_id': requestId,
      'params': params,
      'jwt': localStorage.getItem('jwt'),
    };
    const httpRequest = this.ajaxRequest(this.AJAX_URL, req, respData => {
      const respStatus = getNested(respData, ['status'], '');
      if (respStatus === 'OK') {
        if (onSuccess !== undefined) {
          const data = getNested(respData, ['data'], {});
          onSuccess(data, requestId);
        }
      } else if (respStatus === 'ERRORS') {
        if (onFail !== undefined) {
          const data = getNested(respData, ['data'], {});
          const errors = getNested(respData, ['errors'], []);
          onFail(errors, data, requestId);
        }
      }
    }, respData => {
      if (onFail !== undefined) {
        onFail(respData);
      }
    }, undefined, refreshPageOnLoginError);
    return [requestId, httpRequest];
  }


  /**
   *
   * @param {*} reqUrl string. Request URL.
   * @param {*} reqData dict. Request data.
   * @param {*} onSuccess function(respData).
   * @param {*} onFail function()
   * @param {*} onComplete function().
   * @param {*} refreshPageOnLoginError boolean.
   */
  static ajaxRequest(reqUrl, reqData, onSuccess, onFail, onComplete, refreshPageOnLoginError) {
    const httpRequest = new XMLHttpRequest();
    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState === XMLHttpRequest.DONE) {
        try {
          if (httpRequest.status === 200) {
            if (onSuccess) {
              const respData = JSON.parse(httpRequest.responseText);
              onSuccess(respData);
            }
          } else {
            console.error('A problem with the AJAX request.');
            if (onFail) {
              onFail();
            }
          }

          if (onComplete) {
            onComplete();
          }
        } catch (e) {
          console.error('AJAX request failed.');
          console.error(e);
          if (onFail) {
            onFail();
          }
        }
      }
    };
    httpRequest.open('POST', reqUrl);
    httpRequest.send(JSON.stringify(reqData));
    return httpRequest;
  }
}
