export default {
  translation: {
    'times_one': 'раз',
    // 'times_two': 'раза',
    // 'times_three': 'раза',
    // 'times_four': 'раза',
    'times_few': 'раза',
    'times_many': 'раз',
    // 'times_other': 'раз',
    'years_one': 'год',
    'years_few': 'года',
    'years_many': 'лет',
    'months_one': 'месяц',
    'months_few': 'месяца',
    'months_many': 'месяцев',
    'days_one': 'день',
    'days_few': 'дня',
    'days_many': 'дней',

    'about': 'примерно',
    'Age': 'Возраст',

    'less than a year': 'меньше года',

    'Cat': 'Кошка',
    'Dog': 'Собака',
    'Parrot': 'Попугай',
    'Hamster': 'Хомяк',
    'Rat': 'Крыса',

    'male cat': 'кот',
    'female cat': 'кошка',
    'male dog': 'пёс',
    'female dog': 'собака',
    'male parrot': 'попугай',
    'female parrot': 'попугай',
    'male hamster': 'хомяк',
    'female hamster': 'хомяк',
    'male rat': 'крыса',
    'female rat': 'крыса',

    'Male': 'Мужской',
    'Female': 'Женский',

    'January': 'Январь',
    'February': 'Февраль',
    'March': 'Март',
    'April': 'Апрель',
    'May': 'Май',
    'June': 'Июнь',
    'July': 'Июль',
    'August': 'Август',
    'September': 'Сентабрь',
    'October': 'Октябрь',
    'November': 'Ноябрь',
    'December': 'Декабрь',

    'Do you want to delete all information about {{name}}?': 'Удалить всю информацию о питомце {{name}}?',
    'Yes': 'Да',
    'No': 'Нет',
    'My Pets': 'Мои питомцы',
    'Today Tasks': 'Задачи на сегодня',
    'Tasks for Today': 'Задачи на сегодня',
    'Task Calendar': 'Календарь задач',
    'Tasks': 'Задачи',
    'Task': 'Задача',
    'New Task': 'Новая задача',
    'New task': 'Новая задача',
    'All Tasks': 'Все задачи',
    'Notes': 'Заметки',
    'Username': 'Логин',
    'Password': 'Пароль',
    'Sign in': 'Войти',
    'My Notes': 'Мои заметки',
    'New note': 'Новая заметка',
    'Title': 'Название',
    'Text': 'Текст',
    'Save': 'Сохранить',
    'Cancel': 'Отмена',
    'Do you want to delete this note?': 'Удвлить эту заметку?',
    'New pet': 'Новый питомец',
    'Name': 'Имя',
    'Species': 'Вид',
    'Gender': 'Пол',
    'Birth': 'Дата рождения',
    'Year': 'Год',
    'Month': 'Месяц',
    'Day': 'Число',
    'Add a pet': 'Добавить питомца',
    'Loading': 'Загрузка',
    // 'ERROR': '',
    'could not retrieve data': 'невозможно получить данные',
    'Description': 'Описание',
    'per day': 'в день',
    'a day': 'в день',
    'Starts on': 'Начало',
    'Ends on': 'Конец',
    'Do you want to delete the task?': 'Удалить задачу?',
    'Starts': 'Начало',
    'Ends': 'Конец',

    'My Account': 'Мой аккаунт',
    'Log out': 'Выйти',
    'Click on a pet to select it.': 'Выберите питомца.',

    'Name is mandatory': 'Имя обязательно',
    'Wrong username or password.': 'Неверное имя пользователя или пароль.',

    'New...': 'Создать...',
    'Delete...': 'Удалить...',
    'Visits to vets': 'Визиты к доктору',
    'Visit date': 'Дата визита',
    'At home': 'На дому',
    'Date': 'Дата',
    'Home visit': 'Визит на дому',
    'New record': 'Новая запись',
    'Record': 'Запись',
  }
};
