import React from 'react';
import {PetsList} from "../../components/pets/PetsList";
import PageTitle from "../../Layout/AppMain/PageTitle";
import i18n from "i18next";


export class PetsListPage extends React.Component {
  render() {
    return (
      <>
        <PageTitle
          heading={i18n.t('My Pets')}
          subheading={i18n.t('Click on a pet to select it.')}
        />
        <PetsList />
      </>
    );
  }
}
