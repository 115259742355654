import React from 'react';
import {Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {getNested} from "../../util";
import {tran} from "../../i18n_translations/translate";


export class MyFormField extends React.Component {
  render() {
    return (
      <div style={this.props.isInline ? {display: "inline-block"} : null}>
        {this.props.label ? (
          <Label for={this.props.name}>{this.props.label}</Label>
        ) : null}
        {this.renderChildren()}
        {this.renderErrors()}
      </div>
    );
  }

  renderErrors() {
    const errors = getNested(this.props.errors, [this.props.name], []);
    return (
      <ul className="errors">
        {errors.map((err, idx) => {
          return <li key={`error_${idx}`}>{tran(getNested(err, ['message'], ''))}</li>
        })}
      </ul>
    );
  }

  renderChildren() {
    if (!this.props.render) return null;
    const errors = getNested(this.props.errors, [this.props.name], []);
    const {render, onChangeField, ...childrenProps} = this.props;

    const value = getNested(this.props.values, [this.props.name], null);

    return this.props.render({
      invalid: errors.length > 0,
      value,
      ...childrenProps,
    });
  }
}
