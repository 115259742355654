import React from 'react';
import {Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {MyButton} from "../base/MyButton";
import {MyForm} from "../forms/MyForm";
import {TextInput} from "../forms/TextInput";
import {ServerCommunication} from "../../ServerCommunication";
import {getNested, setNested} from "../../util";
import {ISO_DATE_FORMAT, DateInput} from "../forms/DateInput";
import moment from "moment";
import {connect} from "react-redux";
import {DateTimeInput} from "../forms/DateTimeInput";
import {NumericInput} from "../forms/NumericInput";
import {tran} from "../../i18n_translations/translate";
import {SelectInput} from "../forms/SelectInput";
import {BooleanInput} from "../forms/BooleanInput";


/**
 * Props:
 * taskId: integer.
 * isOpen: boolean.
 * onSave: function(task: object).
 * onClose: function().
 */
class TaskEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'state': '',
      'request_id': 0,
      'errors': [],

      'task': {
      },
      'metadata': {},
    };

    // console.log(moment().format(DateInput.DATE_FORMAT));
  }

  render() {
    const isProcessing = this.state['state'] === 'processing';
    const editorTitle = this.props.taskId === 0 ? tran('New task') : tran('Task');

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          // fullscreen={true}
          toggle={this.onClose}
        >
          <ModalHeader toggle={this.onClose}>{editorTitle}</ModalHeader>
          <ModalBody>
            <MyForm
              disabled={isProcessing}
              onChange={this.onFormChange}
              values={{
                'name': getNested(this.state['task'], this.getFieldPath('name'), ''),
                'description': getNested(this.state['task'], this.getFieldPath('description'), ''),
                'is_everlasting': getNested(this.state['task'], this.getFieldPath('is_everlasting'), ''),
              }}
              errors={this.state['errors']}
              render={(props) => {
                return (
                  <React.Fragment>
                    <FormGroup>
                      <TextInput label={tran('Name')} name="name" {...props} />
                    </FormGroup>
                    <FormGroup>
                      <TextInput label={tran('Description')} name="description" type="textarea" {...props} />
                    </FormGroup>
                    <FormGroup>
                      <BooleanInput title={tran('Everlasting task')} name="is_everlasting" {...props} />
                    </FormGroup>
                  </React.Fragment>
                );
              }}
            />
          </ModalBody>
          <ModalFooter>
            <MyButton color="primary" onClick={this.saveTask} disabled={isProcessing}>{tran('Save')}</MyButton>
            <MyButton onClick={this.onClose} disabled={isProcessing}>{tran('Cancel')}</MyButton>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }

  onFormChange = (name, value) => {
    const task = this.state['task'];
    const path = this.getFieldPath(name);
    setNested(task, path, value);
    // task[name] = value;
    this.setState({
      'task': task,
    });
  }

  getFieldPath(fieldName) {
    return getNested({
      'name': ['name'],
      'description': ['description'],
    }, [fieldName], [fieldName]);
  }

  loadTask() {
    this.setState({
      'state': 'processing',
      'errors': [],
    });

    ServerCommunication.executeCommand('task/load_task', {'task': {'id': this.props.taskId}}, (data, requestId) => {
      const task = getNested(data, ['task'], {});
      this.setState({
        'state': '',
        'task': task,
        'metadata': getNested(data, ['metadata'], {}),
      });
    }, (errors, data, requestId) => {
      this.setState({
        'state': '',
        'errors': errors,
      });
    });
  }

  saveTask = () => {
    this.setState({
      'state': 'processing',
      'errors': [],
    });

    const params = {
      'task': this.state['task'],
      'pet_id': getNested(this.props.selectedPet, ['id'], 0),
    };
    ServerCommunication.executeCommand('task/save_task', params, (data, requestId) => {
      this.setState({
        'state': '',
      });
      this.onSaveTask();
    }, (errors, data, requestId) => {
      this.setState({
        'state': '',
        'errors': errors,
      });
    });
  }

  onSaveTask = () => {
    if (!this.props.onSave) return;
    this.props.onSave();
  }

  onClose = () => {
    if (!this.props.onClose) return;
    this.props.onClose();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.loadTask();
    }
  }
}

const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskEditor);
