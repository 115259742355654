import React from 'react';
import TaskCalendar from "../../components/tasks/task_calendar/TaskCalendar";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import PageTitle from "../../Layout/AppMain/PageTitle";
import {tran} from "../../i18n_translations/translate";


class TaskCalendarPage extends React.Component {
  render() {
    if (this.props.selectedPet === null) return <Redirect to={'/pets/list'} />

    return (
      <React.Fragment>
        <PageTitle
          heading={tran('Task Calendar')}
        />
        <TaskCalendar pet={this.props.selectedPet} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskCalendarPage);
