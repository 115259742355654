export const SET_IS_AUTHENTICATED = 'AUTH/SET_IS_AUTHENTICATED';
export const SET_USERNAME = 'AUTH/SET_USERNAME';
export const SET_USERINFO = 'AUTH/SET_USERINFO';


export const setIsAuthenticated = isAuthenticated => ({
  type: SET_IS_AUTHENTICATED,
  isAuthenticated,
});

export const setUserInfo = props => ({
  type: SET_USERINFO,
  isAuthenticated: props.isAuthenticated,
  username: props.username,
});


export default function reducer(state = {
  username: null,
  isAuthenticated: false,
}, action) {
  switch (action.type) {
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };

    case SET_USERNAME:
      return {
        ...state,
        username: action.username,
        isAuthenticated: !!action.username,
      };

    case SET_USERINFO:
      return {
        ...state,
        username: action.isAuthenticated ? action.username : null,
        isAuthenticated: action.isAuthenticated,
      };
  }
  return state;
}
