import React from 'react';
import {DataType, Table} from 'ka-table';
import {getNested, setNested} from "../../../util";
import {DayCompletions} from "./DayCompletions";
import {ServerCommunication} from "../../../ServerCommunication";
import {IsoDatePicker} from "../../base/IsoDatePicker";
import moment from "moment";
import {getTodayDate} from "../../../common/funcs";
import {TaskDescription} from "../base/TaskDescription";
import {tran} from "../../../i18n_translations/translate";


/**
 * Props:
 * pet: object.
 * mode: string.  // undefined, 'one_day'.
 *    Optional.
 * day: string.  // 'YYYY-mm-dd'.
 *    Optional.
 */
class TaskCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'state': '',
      'request_id': 1,

      'pet_task_ids': [],
      'pet_tasks': {},
      'metadata': {},
      'completions': {},
    };
  }

  render() {
    if (this.state['state'] === 'loading') {
      return <span>{tran('Loading')}...</span>;
    }

    return (
      <React.Fragment>
        <div className={`task_calendar mode__${this.props.mode}`}>
          {this.renderTopControls()}
          {this.renderTable()}
        </div>
      </React.Fragment>
    );
  }

  renderTopControls() {
    const mode = this.props.mode;
    if (mode === 'one_day') return null;

    const startDate = getNested(this.state['metadata'], ['date_range', 0], null);
    const endDate = getNested(this.state['metadata'], ['date_range', 1], null);

    return (
      <div className="task_calendar_date_picker">
        <IsoDatePicker
          selectsRange
          className="date_picker"
          // dateFormat="yyyy-MM-dd"
          dateFormat="dd LLLL yyyy"
          startDate={startDate}
          endDate={endDate}
          onChange={dateRange => {
            const metadata = this.state['metadata'];
            metadata['date_range'] = dateRange;
            this.setState({
              'metadata': metadata,
              'request_id': this.state['request_id'] + 1,
            });
          }}
        />
      </div>
    );
  }

  renderHeaderDate(dt) {
    const dateStr = moment(dt).format('YYYY MMM D dddd');
    const [year, month, day, dow] = dateStr.split(' ');
    return (
      <div className="header_date">
        <p className="year">{year}</p>
        <p className="day">{month} {day}</p>
        <p className="dow">{dow.substring(0, 3)}</p>
      </div>
    );
  }

  renderTable() {
    const WEEKEND_DAYS = [6, 7];
    const metadata = this.state['metadata'];
    const days = getNested(metadata, ['days'], []);
    const today = getNested(metadata, ['today'], null);
    const completions = this.state['completions'];

    const columns = [
      {
        key: 'pet_task.description',
        title: '',
        type: DataType.String,
        width: 300,
        cellType: 'task_description',
        // width: '10rem',
      },
    ].concat(days.map(dayInfo => {
      const dt = getNested(dayInfo, ['dt'], '');
      return {
        // key: dt,
        key: 'completions.' + dt,
        dow: getNested(dayInfo, ['dow'], null),
        // title: dt,
        // title: dateStr,
        title: this.renderHeaderDate(dt),
        type: DataType.String,
        width: 100,
        cellType: 'day',
        today: dt === today,
        // width: '5rem',
      };
    }));

    const dataRows = this.state['pet_task_ids'].map(petTaskId => {
      const petTask = getNested(this.state['pet_tasks'], [petTaskId], {});

      return {
        'pet_task': petTask,
        'completions': getNested(completions, [petTaskId], {}),
        'metadata': {
          'disabled': this.state['state'] === 'disabled',
        },
      };
    });

    return (
      <Table
        columns={columns}
        rowKeyField={'id'}
        data={dataRows}
        // editingMode={EditingMode.Cell}
        childComponents={{
          headCell: {
            elementAttributes: (props) => {
              if (props.column.key === 'pet_task.description') {
                if (this.props.mode === 'one_day') return {
                  style: {
                    ...props.column.style,
                  },
                  className: 'task_description',
                };

                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: 0,
                    zIndex: 10,
                  }
                }
              }

              const dayOfWeek = getNested(props.column, ['dow'], 0);
              const isToday = !!props.column.today;
              return {
                className: `${WEEKEND_DAYS.includes(dayOfWeek) ? 'weekend' : ''} ${isToday ? 'today' : ''}`,
              };
            }
          },
          cell: {
            elementAttributes: (props) => {
              if (props.column.key === 'pet_task.description') {
                if (this.props.mode === 'one_day') return {
                  style: {
                    ...props.column.style,
                  },
                  className: 'task_description',
                };

                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: 0,
                    backgroundColor: '#eee',
                  },
                  className: 'task_description',
                }
              }

              const dayOfWeek = getNested(props.column, ['dow'], 0);
              return {
                className: WEEKEND_DAYS.includes(dayOfWeek) ? 'weekend' : '',
              };
            }
          },
          cellText: {
            content: (props) => {
              switch (props.column.cellType) {
                case 'day':
                  return <DayCompletions onUpdate={this.onUpdateDayCompletion} {...props} />;
                case 'task_description':
                  return <TaskDescription task={getNested(props, ['rowData', 'pet_task', 'task'], {})} />;

                // case 'company.hasLoyalProgram': return <CustomCell {...props}/>;
              }
            }
          },
        }}
      />
    );
  }

  getDateRange(dateRange) {
    if (this.props.mode === 'one_day') return [this.props.day, this.props.day];
    return dateRange;
  }

  loadCalendar() {
    this.setState({
      'state': 'loading',
    });

    const dateRange = this.getDateRange(
      getNested(this.state['metadata'], ['date_range'], [null, null])
    );

    ServerCommunication.executeCommand('pet_task/calendar/get_calendar', {
      'pet_id': getNested(this.props.pet, ['id'], 0),
      'date_range': dateRange,
      'today': getTodayDate(),
    }, data => {
      console.log(data);
      const stateUpdate = {
        'pet_tasks': {},
        'pet_task_ids': [],
        'metadata': {},
        'completions': {},
      };
      const petTasks = getNested(data, ['pet_tasks'], []);
      petTasks.forEach(petTask => {
        const petTaskId = getNested(petTask, ['id'], 0);
        stateUpdate['pet_task_ids'].push(petTaskId);
        stateUpdate['pet_tasks'][petTaskId] = petTask;
      });
      stateUpdate['metadata'] = getNested(data, ['metadata'], {});
      stateUpdate['completions'] = getNested(data, ['completions'], {});

      stateUpdate['state'] = '';
      this.setState(stateUpdate);
    }, (errors, data) => {
      console.error(errors);
    });
  }

  componentDidMount() {
    this.loadCalendar();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const oldDateRange = getNested(prevState['metadata'], ['date_range'], [null, null]);
    const dateRange = getNested(this.state['metadata'], ['date_range'], [null, null]);
    if ((oldDateRange[0] !== dateRange[0]) || (oldDateRange[1] !== dateRange[1])) {
      // this.loadCalendar();
    }

    if ((this.state['request_id'] > prevState['request_id']) && (dateRange[0] !== null) && (dateRange[1] !== null)) {
      this.loadCalendar();
    }
  }

  onUpdateDayCompletion = ({taskId: petTaskId, day, completionIdx, newStatus}) => {
    this.setState({
      'state': 'disabled',
    });

    this.updateDayCompletionOnServer(petTaskId, day, completionIdx, newStatus, data => {
      const completions = this.state['completions'];
      setNested(completions, [petTaskId, day, completionIdx], newStatus);
      this.setState({
        'completions': completions,
        'state': '',
      });
    }, (errors, data) => {
      this.setState({
        'state': '',
      });
    });
  }

  updateDayCompletionOnServer = (petTaskId, day, completionIdx, newStatus, onSuccess, onFail) => {
    ServerCommunication.executeCommand('pet_task/calendar/update_completion', {
      'pet_id': getNested(this.props.pet, ['id'], 0),
      'pet_task_id': petTaskId,
      'day': day,
      'completion_idx': completionIdx,
      'status': newStatus,
    }, onSuccess, onFail);
  }
}

export default TaskCalendar;
