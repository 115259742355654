import React from 'react';
import {setSelectedPet} from "../../reducers/PetOptions";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {getNested} from "../../util";
import TaskWidget from "../tasks/TaskWidget";
import TaskEditor from "../tasks/TaskEditor";
import EditableWidgetsList from "../base/EditableWidgetsList";
import PetTaskEditor from "../tasks/PetTaskEditor";
import PetTaskWidget from "../tasks/PetTaskWidget";


class PetTasksList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.selectedPet === null) return <Redirect to={'/pets/list'} />
    const selectedPet = this.props.selectedPet;

    return (
      <EditableWidgetsList
        command="pet_task/get_list"
        params={{
          'pet_id': getNested(selectedPet, ['id'], 0),
        }}
        renderWidget={props => {
          return (
            <PetTaskWidget petTask={props.row} onUpdate={props.onUpdate} />
          );
        }}
        renderNewRowEditor={props => {
          return (
            <PetTaskEditor
              petTaskId={0}
              isOpen={props.isOpen}
              onSave={props.onSave}
              onClose={props.onClose}
            />
          );
          return (
            <TaskEditor
              taskId={0}
              isOpen={props.isOpen}
              onSave={props.onSave}
              onClose={props.onClose}
            />
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({
  setSelectedPet: selectedPet => dispatch(setSelectedPet(selectedPet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PetTasksList);
