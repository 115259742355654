import React from 'react';
import {Input} from "reactstrap";
import {MyFormField} from "./MyFormField";


export class PasswordInput extends React.Component {
  render() {
    return (
      <>
        <MyFormField
          {...this.props}
          render={props => {
            return <Input type="password" value={this.props.value} onChange={this.onChange}
                          disabled={this.props.disabled} {...props}/>
          }}
        />
      </>
    );
  }

  onChange = (evt) => {
    this.props.onChangeField(this.props.name, evt.target.value);
  }
}
