import React from 'react';
import TasksList from "../../components/tasks/TasksList";
import NotesList from "../../components/notes/NotesList";
import PageTitle from "../../Layout/AppMain/PageTitle";
import {tran} from "../../i18n_translations/translate";


export class NotesListPage extends React.Component {
  render() {
    return (
      <>
        <PageTitle
          heading={tran('My Notes')}
        />
        <NotesList />
      </>
    );
  }
}
