export function getNested(obj, path, defaultValue) {
  try {
    for (let i = 0; i < path.length; i++) {
      const key = path[i];
      obj = obj[key];
      if (obj === undefined) return defaultValue;
    }
    if (obj === undefined) return defaultValue;
    return obj;
  } catch (error) {
    return defaultValue;
  }
}


export function setNested(obj, path, value) {
  for (let i = 0; i < path.length; i++) {
    const key = path[i];

    if (i === path.length - 1) {
      obj[key] = value;
      return true;
    }

    if (obj[key] === undefined) {
      obj[key] = {};
      obj = obj[key];
      continue;
    }

    if ((typeof(obj[key]) == 'object') && (obj[key] !== null)) {
      obj = obj[key];
      continue;
    }

    obj[key] = {};
    obj = obj[key];
  }
}


export function capitalize(s) {
  if (typeof(s) !== 'string') return s;
  if (s.length === 0) return '';
  return s[0].toUpperCase() + s.slice(1);
}
