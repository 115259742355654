import React from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";
import {ISO_DATE_FORMAT} from "../forms/DateInput";


export class IsoDatePicker extends React.Component {
  render() {
    return (
      <DatePicker
        {...this.props}
        onChange={this.onChange}
        selected={this.convertIsoToDate(this.props.selected)}
        startDate={this.convertIsoToDate(this.props.startDate)}
        endDate={this.convertIsoToDate(this.props.endDate)}
      />
    );
  }

  onChange = (dateOrDateRange, x) => {
    if (!this.props.onChange) return;

    if (this.props.selectsRange) {
      const dateRange = dateOrDateRange;
      const convertedDateRange = dateRange.map(x => this.convertDateToIso(x));
      this.props.onChange(convertedDateRange);
    } else {
      const date = dateOrDateRange;
      const convertedDate = this.convertDateToIso(date);
      this.props.onChange(convertedDate);
    }
  }

  convertDateToIso(dt) {
    if ([null, undefined].includes(dt)) return dt;
    return moment(dt).format(ISO_DATE_FORMAT);
  }

  convertIsoToDate(isoDate) {
    if ([null, undefined].includes(isoDate)) return isoDate;
    return new Date(isoDate);
  }
}
