export const SET_SELECTED_PET = 'AUTH/SET_SELECTED_PET';


export const setSelectedPet = selectedPet => ({
  type: SET_SELECTED_PET,
  selectedPet: selectedPet,
});


export default function reducer(state = {
  selectedPet: null,
}, action) {
  switch (action.type) {
    case SET_SELECTED_PET:
      return {
        ...state,
        selectedPet: action.selectedPet,
      };
  }
  return state;
}
