import React, {Fragment} from 'react';
import cx from 'classnames';

import {connect} from 'react-redux';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import HeaderLogo from '../AppLogo';

import SearchBox from './Components/SearchBox';
import UserBox from './Components/UserBox';
import {getNested} from "../../util";
import {SpeciesGender} from "../../components/pets/SpeciesGender";
import {PetAge} from "../../components/pets/PetAge";

class Header extends React.Component {
    render() {
        let {
            headerBackgroundColor,
            enableMobileMenuSmall,
            enableHeaderShadow
        } = this.props;
        return (
            <Fragment>
                <TransitionGroup>
                    <CSSTransition
                        component="div"
                        className={cx("app-header", headerBackgroundColor, {'header-shadow': enableHeaderShadow})}
                        appear={true}
                        timeout={1500}
                        enter={false}
                        exit={false}>
                        <div>

                            <HeaderLogo/>
                            {this.renderSelectedPet()}

                            <div className={cx(
                                "app-header__content",
                                {'header-mobile-open': enableMobileMenuSmall},
                            )}>
                                <div className="app-header-left">
                                    {/*<SearchBox/>*/}
                                </div>
                                <div className="app-header-right">
                                    <UserBox/>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        );
    }

    renderSelectedPet = () => {
        const selectedPet = this.props.selectedPet;
        if (!selectedPet) return null;

        const name = getNested(selectedPet, ['name'], '');

        return (
          <div className="header-btn-lg left pe-0">
              <div className="widget-content p-0">
                  <div className="widget-content-wrapper pet_info">
                      <div className="right me-3 header-user-info">
                          <div className="widget-heading">
                              {name}
                          </div>
                          <div className="widget-subheading">
                              <SpeciesGender pet={selectedPet} />
                              <PetAge pet={selectedPet} before={<span>, </span>} />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        );
    }
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
    selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);