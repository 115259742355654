import React from 'react';
import {DayTasks} from "./DayTasks";
import {getTodayDate} from "../../common/funcs";


export class TodayTasks extends React.Component {
  render() {
    const day = getTodayDate();
    return <DayTasks pet={this.props.pet} day={day} />;
  }
}
