import React from 'react';
import {FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {MyButton} from "../base/MyButton";
import {MyForm} from "../forms/MyForm";
import {TextInput} from "../forms/TextInput";
import {ServerCommunication} from "../../ServerCommunication";
import {getNested, setNested} from "../../util";
import {connect} from "react-redux";
import {HtmlInput} from "../forms/HtmlInput";
import {tran} from "../../i18n_translations/translate";


/**
 * Props:
 * record: integer.
 * isOpen: boolean.
 * onSave: function(record: object).
 * onClose: function().
 * loadUrl: string.
 */
class ModalEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'state': '',
      'request_id': 0,
      'errors': [],

      'record': {},
      'metadata': {},
    };
  }

  render() {
    const isProcessing = this.state['state'] === 'processing';
    const editorTitle = this.props.recordId === 0 ? tran('New record') : tran('Record');

    const values = {};
    Object.keys(this.props.valuesMap).forEach(fieldName => {
      values[fieldName] = getNested(this.state['record'], this.getFieldPath(fieldName), null);
    });
    console.log(values);

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          // isOpen={true}
          // toggle={this.onClose}
          className="wide_modal"
        >
          <ModalHeader toggle={this.onClose}>{editorTitle}</ModalHeader>
          <ModalBody>
            <MyForm
              disabled={isProcessing}
              onChange={this.onFormChange}
              values={values}
              errors={this.state['errors']}
              render={(props) => {
                return this.renderFormBody(props);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <MyButton color="primary" onClick={this.saveRecord} disabled={isProcessing}>{tran('Save')}</MyButton>
            <MyButton onClick={this.onClose} disabled={isProcessing}>{tran('Cancel')}</MyButton>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }

  renderFormBody(props) {
    return this.props.renderFormBody(props);
  }

  onFormChange = (name, value) => {
    const record = this.state['record'];
    const path = this.getFieldPath(name);
    setNested(record, path, value);
    this.setState({
      'record': record,
    });
  }

  getFieldPath(fieldName) {
    return getNested(this.valuesMap, [fieldName], [fieldName]);
  }

  loadRecord() {
    this.setState({
      'state': 'processing',
      'record': {},
      'errors': [],
    });

    const recordId = getNested(this.props.record, ['id'], 0);

    const petId = getNested(this.props.selectedPet, ['id'], 0);
    const params = {
      'record': {
        'id': recordId,
      },
      'pet': {
        'id': petId,
      },
    };
    ServerCommunication.executeCommand(this.props.loadUrl, params, (data, requestId) => {
      this.setState({
        'state': '',
        'record': getNested(data, ['record'], {}),
        'metadata': getNested(data, ['metadata'], {}),
      });
    }, (errors, data, requestId) => {
      this.setState({
        'state': '',
        'errors': errors,
      });
    });
  }

  saveRecord = () => {
    this.setState({
      'state': 'processing',
      'errors': [],
    });

    const petId = getNested(this.props.selectedPet, ['id'], 0);
    const params = {
      'record': this.state['record'],
      'pet': {
        'id': petId,
      },
    };
    ServerCommunication.executeCommand(this.props.saveUrl, params, (data, requestId) => {
      const record = getNested(data, ['record'], {});
      this.setState({
        'state': '',
      });
      this.onSaveRecord(record);
    }, (errors, data, requestId) => {
      this.setState({
        'state': '',
        'errors': errors,
      });
    });
  }

  onSaveRecord = (record) => {
    if (!this.props.onSave) return;
    this.props.onSave(record);
  }

  onClose = () => {
    if (!this.props.onClose) return;
    this.props.onClose();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.loadRecord();
    }
  }
}

const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditor);
