import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import {Route, withRouter} from 'react-router-dom';

import ResizeDetector from 'react-resize-detector';

import AppMain from '../../Layout/AppMain';
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import TabExample from "../../DemoPages/Components/Tabs";
import NotificationsExamples from "../../DemoPages/Components/Notifications";
import TooltipsPopoversExample from "../../DemoPages/Components/TooltipsPopovers";
import ProgressBarsExamples from "../../DemoPages/Components/ProgressBar";
import CarouselExample from "../../DemoPages/Components/Carousel";
import ModalsExample from "../../DemoPages/Components/Modal";
import MapsExample from "../../DemoPages/Components/Maps";
import AppFooter from "../../Layout/AppFooter";
import LoginPage from "../Login";
import {getUserConfiguration} from "../../common/funcs";
import {setEnableMobileMenu, setEnableMobileMenuSmall} from "../../reducers/ThemeOptions";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
      width: undefined
    };

  }

  onResize = (width) => this.setState({width});

  render() {
    const {width} = this.state;

    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props;

    if (!this.props.isAuthenticated) {
      return (
        <LoginPage/>
      )
    }

    return (
      <Fragment>
        <div className={cx(
          "app-container app-theme-" + colorScheme,
          {'fixed-header': enableFixedHeader},
          {'fixed-sidebar': enableFixedSidebar || width < 1250},
          {'fixed-footer': enableFixedFooter},
          {'closed-sidebar': enableClosedSidebar || width < 1250},
          {'closed-sidebar-mobile': closedSmallerSidebar || width < 1250},
          {'sidebar-mobile-open': enableMobileMenu},
        )}>
          <AppHeader/>
          <div className="app-main">
            <AppSidebar/>
            <div className="app-main__outer">
              <div className="app-main__inner">
                <AppMain/>
              </div>
              {/*<AppFooter/>*/}
            </div>
          </div>

          <ResizeDetector handleWidth onResize={this.onResize}/>
        </div>
      </Fragment>
    )
  }

  componentDidMount() {
    getUserConfiguration(this.props.dispatch);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
      getUserConfiguration(this.props.dispatch);
    }
  }
}

const mapStateToProp = state => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

  isAuthenticated: state.UserInfo.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  // setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
  // setEnableMobileMenuSmall: enable => dispatch(setEnableMobileMenuSmall(enable)),
  dispatch: dispatch,
});

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Main));