import React from 'react';
import {MyFormField} from "./MyFormField";
import NumInput from 'react-numeric-input';


/**
 * Props:
 * integer: boolean.
 */
export class NumericInput extends React.Component {
  render() {
    return (
      <>
        <MyFormField
          {...this.props}
          render={props => {
            return <div className="numeric_input">
              <NumInput
                {...props}
                onChange={this.onChange}
                className="fasdf"
              />
            </div>
          }}
        />
      </>
    );
  }

  onChange = (value) => {
    if (this.props.integer) value = Math.floor(value);
    this.props.onChangeField(this.props.name, value);
  }
}
