import {tran} from "../../i18n_translations/translate";

export const PetsNav = [
    {
        icon: 'lnr-list',
        label: tran(tran('My Pets')),
        to: '#/pets/list',
    },
];


export const TasksNav = [
    {
        icon: 'lnr-list',
        label: tran('Today Tasks'),
        to: '#/tasks/today',
    },
    {
        icon: 'lnr-list',
        label: tran('Task Calendar'),
        to: '#/tasks/calendar',
    },
    {
        icon: 'lnr-list',
        label: tran('Tasks'),
        to: '#/tasks/list',
    },
];


export const NotesNav = [
    {
        icon: 'lnr-list',
        label: tran('Notes'),
        to: '#/notes/list',
    },
];


export const VetNav = [
    {
        icon: 'lnr-list',
        label: tran('Visits to vets'),
        to: '#/vet/visits/list',
    },
];


export const SettingsNav = [
    {
        icon: 'lnr-list',
        label: tran('Tasks'),
        to: '#/settings/tasks/list',
    },
];
