import React from 'react';
import {Input} from "reactstrap";
import {MyFormField} from "./MyFormField";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import Switch from '@mui/material/Switch';


export class BooleanInput extends React.Component {
  render() {
    return (
      <>
        <MyFormField
          {...this.props}
          render={props => {
            // return <label><input type="checkbox" /> {props.title}</label>;
            return (
              <label>
                <Switch checked={props.value} onChange={this.onChange} disabled={props.disabled}
                        {...props}/> {props.title}
              </label>
            );
            return <Input type="text" value={props.value} onChange={this.onChange}
                          disabled={props.disabled} {...props} />
          }}
        />
      </>
    );
  }

  onChange = (evt) => {
    this.props.onChangeField(this.props.name, evt.target.checked);
  }
}
