import React from 'react';
import TaskCalendar from "./task_calendar/TaskCalendar";


/**
 * Props:
 * pet: object.
 * day: string.
 */
export class DayTasks extends React.Component {
  render() {
    return (
      <TaskCalendar
        mode="one_day"
        day={this.props.day}
        pet={this.props.pet}
      />
    );
  }
}
