export default {
  translation: {
    'times_one': 'time',
    'times_other': 'times',
    'years_one': 'year',
    'years_other': 'years',
    'months_one': 'month',
    'months_other': 'months',
    'days_one': 'day',
    'days_other': 'days',
  }
};
