import React from 'react';
import TasksList from "../../components/tasks/TasksList";
import PageTitle from "../../Layout/AppMain/PageTitle";
import {tran} from "../../i18n_translations/translate";
import PetTasksList from "../../components/pet_tasks/PetTasksList";


export class PetTasksListPage extends React.Component {
  render() {
    return (
      <>
        <PageTitle
          heading={tran('All Tasks')}
        />
        <PetTasksList />
      </>
    );
  }
}
