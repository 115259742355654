import React from 'react';
import {getNested} from "../../util";


/**
 * Props:
 * errors: list.
 */
export class Errors extends React.Component {
  render() {
    const errors = getNested(this.props, ['errors'], []);
    return (
      <ul className="errors">
        {errors.map((error, idx) => {
          const message = getNested(error, ['message'], '');
          return <li key={`error_${idx}`}>{message}</li>;
        })}
      </ul>
    );
  }
}
