import React from 'react';
import {Button, Card, CardBody, CardSubtitle, CardTitle, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {getNested} from "../../util";
import {MyButton} from "../base/MyButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCross, faEdit, faNotesMedical, faRemove} from "@fortawesome/free-solid-svg-icons";
import {ServerCommunication} from "../../ServerCommunication";
import {Errors} from "../base/Errors";
import {PetEditor} from "./PetEditor";
import {setEnableMobileMenu} from "../../reducers/ThemeOptions";
import {connect} from "react-redux";
import {setSelectedPet} from "../../reducers/PetOptions";
import {SpeciesGender} from "./SpeciesGender";
import {tran} from "../../i18n_translations/translate";
import {PetAge} from "./PetAge";


/**
 * Props:
 * pet: object.
 * onUpdate: function(pet: object).
 * onSelect: function(pet: object).
 */
class PetWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'confirmation': {
        'is_open': false,
        'title': '',
        'body': null,
        'on_confirm': null,
        'errors': [],
      },
      'is_processing': false,
      'is_editor_open': false,
    };
  }

  render() {
    const pet = this.props.pet;
    const petId = getNested(pet, ['id'], '');
    const name = getNested(pet, ['name'], '');
    const species = getNested(pet, ['species_code'], '');
    const gender = getNested(pet, ['gender'], '');

    const selectedPetId = getNested(this.props.selectedPet, ['id'], 0);
    const isSelected = petId && (petId === selectedPetId);

    return (
      <React.Fragment>
        <Card className={`pet_widget main-card mb-3 ${isSelected ? 'selected' : ''}`} onClick={this.expandNote}>
          <CardBody>
            <CardTitle>{name}</CardTitle>
            <CardSubtitle>
              <SpeciesGender speciesCode={species} gender={gender} /> <br/>
              <PetAge pet={pet} />
            </CardSubtitle>
            <div className="controls">
              <MyButton outline className="mb-2 me-2 border-0 btn-transition" color="secondary"
                        onClick={this.onClickEdit}>
                <FontAwesomeIcon icon={faEdit}/>
              </MyButton>
              <MyButton outline className="mb-2 me-2 border-0 btn-transition" color="danger"
                        onClick={this.onClickDelete}>
                <FontAwesomeIcon icon={faRemove}/>
              </MyButton>
            </div>
          </CardBody>
        </Card>

        {this.renderConfirmation()}

        <PetEditor
          petId={petId}
          isOpen={this.state['is_editor_open']}
          onSave={this.onSavePet}
          onClose={() => {
            this.setState({
              'is_editor_open': false,
            });
          }}
        />
      </React.Fragment>
    );
  }

  onClickEdit = (evt) => {
    evt.stopPropagation();

    this.setState({
      'is_editor_open': true,
    });
  }

  onClickDelete = (evt) => {
    evt.stopPropagation();

    const name = getNested(this.props.pet, ['name'], '');
    this.setState({
      'confirmation': {
        'is_open': true,
        'title': '',
        // 'body': `Do you want to delete all information about ${name}?`,
        'body': tran(`Do you want to delete all information about {{name}}?`, {name: name}),
        'on_confirm': this.deletePet,
      }
    });
  }

  expandNote = () => {
    this.props.setSelectedPet(this.props.pet);
    // if (!this.props.onSelect) return;
    // this.props.onSelect(this.props.pet);
  }

  deletePet = () => {
    const petId = getNested(this.props.pet, ['id'], 0);
    this.setState({
      'is_processing': true,
    });
    ServerCommunication.executeCommand('pets/delete_pet', {'pet': {'id': petId}}, data => {
      this.closeConfirmation();
      if (this.props.onUpdate) {
        this.props.onUpdate(getNested(data, ['pet'], {}));
      }
    }, (errors, data) => {
      const confirmation = getNested(this.state, ['confirmation'], {});
      confirmation['errors'] = errors;
      this.setState({
        'confirmation': confirmation,
      });
    });
  }

  onSavePet = () => {
    this.closeConfirmation();
    if (this.props.onUpdate) {
      this.props.onUpdate(this.props.pet);
    }
  }

  renderConfirmation = () => {
    const confirmation = this.state['confirmation'];
    const isDisabled = this.state['is_processing'];
    const errors = getNested(this.state['confirmation'], ['errors'], []);
    return (
      <Modal
        isOpen={confirmation['is_open']}
        toggle={this.closeConfirmation}
      >
        <ModalHeader>{confirmation['title']}</ModalHeader>
        <ModalBody>{confirmation['body']}</ModalBody>
        <ModalFooter>
          <MyButton disabled={isDisabled} color="danger" onClick={confirmation['on_confirm']}>{tran('Yes')}</MyButton>
          <MyButton disabled={isDisabled} onClick={this.closeConfirmation}>{tran('No')}</MyButton>
        </ModalFooter>

        {errors.length ? (
          <ModalFooter style={{justifyContent: 'flex-start'}}>
            <Errors errors={this.state['confirmation']['errors']} />
          </ModalFooter>
        ) : null}
      </Modal>
    );
  }

  closeConfirmation = () => {
    this.setState({
      'is_processing': false,
      'confirmation': {
        'is_open': false,
      },
    });
  }
}

const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({
  setSelectedPet: selectedPet => dispatch(setSelectedPet(selectedPet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PetWidget);
