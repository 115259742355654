import React, {Fragment} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  Card, CardBody, Row, Col,
  CardTitle, Form, FormGroup, Label, Input, FormFeedback, FormText, Button
} from 'reactstrap';
import LoginForm from "./LoginForm";

export default class LoginPage extends React.Component {
  render() {
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            className="TabsAnimation"
            appear={true}
            timeout={0}
            enter={false}
            exit={false}>
            <div>
              <br/><br/><br/><br/><br/><br/>
              <br/><br/><br/><br/><br/><br/>
              <Row>
                <Col md="3"></Col>
                <Col md="6">
                  <Card className="main-card mb-3">
                    <CardBody>
                      <LoginForm />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}
