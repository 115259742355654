import React from 'react';
import {Form, FormGroup} from "reactstrap";
import {getNested} from "../../util";
import {tran} from "../../i18n_translations/translate";


/**
 * Props:
 * values: object.
 * errors: array of dict:
 *    {
 *      'message': string,
 *      'code': any,
 *    }
 * onSubmit: function().
 * render: function(props).
 *    props: dict:
 *    {
 *        onChangeField: function(name, value).
 *        disabled: boolean.
 *        errors: object: fieldName -> list of errors.
 *    }
 * onChange: function(name, value).
 * autocomplete: string.
 */
export class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'errors': {},
    };
  }

  render() {
    return (
      <>
        <Form
          onSubmit={this.onSubmit}
          autoComplete={this.props.autocomplete}
        >
          {this.renderChildren()}
          {this.renderCommonErrors()}
        </Form>
      </>
    );
  }

  renderChildren() {
    if (!this.props.render) return null;
    return this.props.render({
      onChangeField: this.onChangeField,
      disabled: this.props.disabled,
      errors: this.state['errors'],
      values: this.props.values,
    });
  }

  renderCommonErrors() {
    const errors = getNested(this.state['errors'], [''], []);
    if (errors.length === 0) return null;

    return (
      <ul className="errors">
        {
          errors.map((err, idx) => {
            return (
              <li key={`error_${idx}`} className="error">{tran(getNested(err, ['message'], ''))}</li>
            );
          })
        }
      </ul>
    );
  }

  onChangeField = (name, value) => {
    this.props.onChange(name, value);
  }

  onSubmit = () => {
    this.props.onSubmit(this.props.values);
  }

  refreshErrors() {
    this.setState({
      'errors': this.getErrorsDict(this.props.errors),
    });
  }

  getErrorsDict() {
    const res = {};
    getNested(this.props, ['errors'], []).forEach(errInfo => {
      const fieldName = getNested(errInfo, ['field_name'], '');
      const currentFieldErrors = getNested(res, [fieldName], []);
      currentFieldErrors.push(errInfo);
      res[fieldName] = currentFieldErrors;
    });
    return res;
  }

  componentDidMount() {
    this.refreshErrors();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.errors !== prevProps.errors) {
      this.refreshErrors();
    }
  }
}
