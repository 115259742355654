import {capitalize, getNested} from "../../util";
import {tran} from "../../i18n_translations/translate";


/**
 * Props:
 * pet: object. Optional.
 * speciesCode: string. Optional.
 * gender: string. Optional.
 * capitalize: boolean.
 */
export function SpeciesGender(props) {
  const pet = getNested(props, ['pet'], null);
  let speciesCode = getNested(props, ['speciesCode'], '');
  let gender = getNested(props, ['gender'], '');

  if (pet) {
    speciesCode = getNested(pet, ['species_code'], '');
    gender = getNested(pet, ['gender'], '');
  }

  if (!gender) {
    gender = '';
  }
  if (!speciesCode) {
    speciesCode = '';
  }

  if (props.capitalize) {
    return <span>{capitalize(tran(`${gender} ${speciesCode}`))}</span>;
  }
  return <span>{tran(`${gender} ${speciesCode}`)}</span>;
}
