import React, {Fragment} from 'react';
import {Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {ServerCommunication} from "../../ServerCommunication";
import {connect} from "react-redux";
import {setIsAuthenticated} from "../../reducers/UserInfo";
import {MyForm} from "../../components/forms/MyForm";
import {TextInput} from "../../components/forms/TextInput";
import {PasswordInput} from "../../components/forms/PasswordInput";
import {MyButton} from "../../components/forms/MyButton";
import {getNested} from "../../util";
import {tran} from "../../i18n_translations/translate";


class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'state': '',  // '', loading
      'errors': [],

      'username': '',
      'password': '',
    };
  }

  render() {
    const isLoading = this.state['state'] === 'loading';

    return (
      <Fragment>
        <MyForm
          disabled={isLoading}
          onSubmit={this.onSubmit}
          values={{
            'username': this.state['username'],
            'password': this.state['password'],
          }}
          onChange={this.onChange}
          errors={this.state['errors']}
          render={props => {
            return (
              <>
                <FormGroup>
                  <TextInput label={tran('Username')} name="username" {...props} />
                </FormGroup>
                <FormGroup>
                  <PasswordInput label={tran('Password')} name="password" {...props} />
                </FormGroup>
                <FormGroup>
                  <MyButton type="submit" color="primary" className="mt-2" {...props}>{tran('Sign in')}</MyButton>
                </FormGroup>
              </>
            );
          }}
        />
      </Fragment>
    )
  }

  onChange = (name, value) => {
    if (this.state['errors'].length > 0) {
      this.setState({
        'errors': [],
      });
    }

    this.setState({
      [name]: value,
    });
  }

  onSubmit = () => {
    this.setState({
      'state': 'loading',
      'errors': [],
    });
    const params = {
      'username': this.state['username'],
      'password': this.state['password'],
    };
    ServerCommunication.executeCommand('auth/login', params, (respData) => {
      localStorage.setItem('jwt', getNested(respData, ['jwt'], ''));

      this.props.setIsAuthenticated(true);
      this.setState({
        'state': '',
      });
    }, (errors, respData) => {
      this.setState({
        'errors': errors,
        'state': '',
      });
    });
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setIsAuthenticated: enable => dispatch(setIsAuthenticated(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
