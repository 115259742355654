import React from 'react';
import {FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {MyButton} from "../base/MyButton";
import {MyForm} from "../forms/MyForm";
import {TextInput} from "../forms/TextInput";
import {ServerCommunication} from "../../ServerCommunication";
import {getNested, setNested} from "../../util";
import {connect} from "react-redux";
import {HtmlInput} from "../forms/HtmlInput";
import {tran} from "../../i18n_translations/translate";


/**
 * Props:
 * noteId: integer.
 * isOpen: boolean.
 * onSave: function(note: object).
 * onClose: function().
 */
class NoteEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'state': '',
      'request_id': 0,
      'errors': [],

      'note': {},
      'metadata': {},
    };
  }

  render() {
    const isProcessing = this.state['state'] === 'processing';
    const editorTitle = this.props.noteId === 0 ? tran('New note') : getNested(this.state['note'], ['title'], '');

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.onClose}
          className="wide_modal"
        >
          <ModalHeader toggle={this.onClose}>{editorTitle}</ModalHeader>
          <ModalBody>
            <MyForm
              disabled={isProcessing}
              onChange={this.onFormChange}
              values={{
                'title': getNested(this.state['note'], this.getFieldPath('title'), ''),
                'note_html': getNested(this.state['note'], this.getFieldPath('note_html'), ''),
              }}
              errors={this.state['errors']}
              render={(props) => {
                return (
                  <React.Fragment>
                    <FormGroup>
                      <TextInput label={tran('Title')} name="title" {...props} />
                      <HtmlInput label={tran('Text')} name="note_html" {...props} />
                    </FormGroup>
                  </React.Fragment>
                );
              }}
            />
          </ModalBody>
          <ModalFooter>
            <MyButton color="primary" onClick={this.saveNote} disabled={isProcessing}>{tran('Save')}</MyButton>
            <MyButton onClick={this.onClose} disabled={isProcessing}>{tran('Cancel')}</MyButton>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }

  onFormChange = (name, value) => {
    const note = this.state['note'];
    const path = this.getFieldPath(name);
    setNested(note, path, value);
    this.setState({
      'note': note,
    });
  }

  getFieldPath(fieldName) {
    return getNested({
      'note_html': ['note_html'],
    }, [fieldName], [fieldName]);
  }

  loadNote() {
    this.setState({
      'state': 'processing',
      'errors': [],
    });

    ServerCommunication.executeCommand('notes/load_note', {'note': {'id': this.props.noteId}}, (data, requestId) => {
      const note = getNested(data, ['note'], {});
      this.setState({
        'state': '',
        'note': note,
        'metadata': getNested(data, ['metadata'], {}),
      });
    }, (errors, data, requestId) => {
      this.setState({
        'state': '',
        'errors': errors,
      });
    });
  }

  saveNote = () => {
    this.setState({
      'state': 'processing',
      'errors': [],
    });

    const params = {
      'note': this.state['note'],
      'pet_id': getNested(this.props.selectedPet, ['id'], 0),
    };
    ServerCommunication.executeCommand('notes/save_note', params, (data, requestId) => {
      this.setState({
        'state': '',
      });
      this.onSaveNote();
    }, (errors, data, requestId) => {
      this.setState({
        'state': '',
        'errors': errors,
      });
    });
  }

  onSaveNote = () => {
    if (!this.props.onSave) return;
    this.props.onSave();
  }

  onClose = () => {
    if (!this.props.onClose) return;
    this.props.onClose();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.loadNote();
    }
  }
}

const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteEditor);
