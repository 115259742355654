import React from 'react';
import DatePicker from "react-datepicker";
import {FormFeedback, Input, Label} from "reactstrap";
import {MyFormField} from "./MyFormField";
import moment from "moment";


export const ISO_DATE_FORMAT = 'YYYY-MM-DD';

export class DateInput extends React.Component {
  render() {
    return (
      <>
        <MyFormField
          {...this.props}
          render={props => {
            // const dateValue = moment(props.value, this.DATE_FORMAT);
            return <DatePicker
              {...props}
              // dateFormat="yyyy-mm-dd"
              selected2={props.value}
              // selected={moment('2023-05-12')}
              onChange={this.onChange}
              disabled={props.disabled}
              isClearable={true}
              renderAriaLiveRegion={false}
            />
          }}
        />
      </>
    );
  }

  onChange = (date) => {
    console.log(moment().format('YYYY-MM-DD HH:mm:ss'));
    console.log(moment(date).format('YYYY-MM-DD HH:mm:ss'));
    console.log(date.toISOString());
    this.props.onChangeField(this.props.name, moment(date).format(ISO_DATE_FORMAT));
  }
}
