import React from 'react';
import {Button, Card, CardBody, CardSubtitle, CardTitle, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {getNested} from "../../util";
import {MyButton} from "../base/MyButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCross, faEdit, faNotesMedical, faRemove} from "@fortawesome/free-solid-svg-icons";
import {ServerCommunication} from "../../ServerCommunication";
import {Errors} from "../base/Errors";
import {connect} from "react-redux";
import TaskEditor from "./TaskEditor";
import {TaskDescription} from "./base/TaskDescription";
import {tran} from "../../i18n_translations/translate";
import PetTaskEditor from "./PetTaskEditor";


/**
 * Props:
 * petTask: object.
 * onUpdate: function(task: object).
 * onSelect: function(task: object).
 */
class PetTaskWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'confirmation': {
        'is_open': false,
        'title': '',
        'body': null,
        'on_confirm': null,
        'errors': [],
      },
      'is_processing': false,
      'is_editor_open': false,
    };
  }

  render() {
    const petTask = this.props.petTask;
    const task = getNested(petTask, ['task'], {});
    const petTaskId = getNested(petTask, ['id'], '');
    const name = getNested(task, ['name'], '');
    const description = getNested(task, ['description'], '');
    const period = getNested(petTask, ['data', 'period'], 'day');
    const timesPerPeriod = getNested(petTask, ['data', 'times_per_period'], 1);
    const firstDay = getNested(petTask, ['data', 'first_day'], '');
    const lastDay = getNested(petTask, ['data', 'last_day'], '');
    const isEverlasting = getNested(petTask, ['is_everlasting'], false);

    return (
      <React.Fragment>
        <Card className={`pet_widget main-card mb-3`} onClick={this.selectPet}>
          <CardBody>
            {/*<CardTitle>Title</CardTitle>*/}
            <TaskDescription task={task} />
            {/*<CardSubtitle><pre>{description}</pre></CardSubtitle>*/}
            {isEverlasting ? (
              <p>{tran('Permanent task')}</p>
            ) : (
              <p>{timesPerPeriod} time(s) a {period}<br/>
                {firstDay ? <span>Starts: {firstDay}</span> : null}<br/>
                {lastDay ? <span>Ends: {lastDay}</span> : null}
              </p>
            )}
            <div className="controls">
              <MyButton outline className="mb-2 me-2 border-0 btn-transition" color="secondary"
                        onClick={this.onClickEdit}>
                <FontAwesomeIcon icon={faEdit}/>
              </MyButton>
              <MyButton outline className="mb-2 me-2 border-0 btn-transition" color="danger"
                        onClick={this.onClickDelete}>
                <FontAwesomeIcon icon={faRemove}/>
              </MyButton>
            </div>
          </CardBody>
        </Card>

        {this.renderConfirmation()}

        <PetTaskEditor
          petTaskId={petTaskId}
          isOpen={this.state['is_editor_open']}
          onSave={this.onSaveTask}
          onClose={() => {
            this.setState({
              'is_editor_open': false,
            });
          }}
        />
      </React.Fragment>
    );
  }

  onClickEdit = (evt) => {
    evt.stopPropagation();

    this.setState({
      'is_editor_open': true,
    });
  }

  onClickDelete = (evt) => {
    evt.stopPropagation();

    this.setState({
      'confirmation': {
        'is_open': true,
        'title': '',
        'body': tran(`Do you want to delete the task?`),
        'on_confirm': this.deleteTask,
      }
    });
  }

  deleteTask = () => {
    const taskId = getNested(this.props.petTask, ['id'], 0);
    this.setState({
      'is_processing': true,
    });
    ServerCommunication.executeCommand('pet_task/delete_task', {'pet_task': {'id': taskId}}, data => {
      this.closeConfirmation();
      if (this.props.onUpdate) {
        this.props.onUpdate(getNested(data, ['pet_task'], {}));
      }
    }, (errors, data) => {
      const confirmation = getNested(this.state, ['confirmation'], {});
      confirmation['errors'] = errors;
      this.setState({
        'confirmation': confirmation,
      });
    });
  }

  onSaveTask = () => {
    this.closeConfirmation();
    if (this.props.onUpdate) {
      this.props.onUpdate(this.props.petTask);
    }
  }

  renderConfirmation = () => {
    const confirmation = this.state['confirmation'];
    const isDisabled = this.state['is_processing'];
    const errors = getNested(this.state['confirmation'], ['errors'], []);
    return (
      <Modal
        isOpen={confirmation['is_open']}
        toggle={this.closeConfirmation}
      >
        <ModalHeader>{confirmation['title']}</ModalHeader>
        <ModalBody>{confirmation['body']}</ModalBody>
        <ModalFooter>
          <MyButton disabled={isDisabled} color="danger" onClick={confirmation['on_confirm']}>{tran('Yes')}</MyButton>
          <MyButton disabled={isDisabled} onClick={this.closeConfirmation}>{tran('No')}</MyButton>
        </ModalFooter>

        {errors.length ? (
          <ModalFooter style={{justifyContent: 'flex-start'}}>
            <Errors errors={this.state['confirmation']['errors']} />
          </ModalFooter>
        ) : null}
      </Modal>
    );
  }

  closeConfirmation = () => {
    this.setState({
      'is_processing': false,
      'confirmation': {
        'is_open': false,
      },
    });
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PetTaskWidget);
