import React from 'react';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAccordion
} from "reactstrap";
import {getNested} from "../../util";
import {MyButton} from "../base/MyButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCross, faEdit, faNotesMedical, faRemove} from "@fortawesome/free-solid-svg-icons";
import {ServerCommunication} from "../../ServerCommunication";
import {Errors} from "../base/Errors";
import {connect} from "react-redux";
import NoteEditor from "./NoteEditor";
import {tran} from "../../i18n_translations/translate";


/**
 * Props:
 * note: object.
 * onUpdate: function(note: object).
 * onSelect: function(note: object).
 */
class NoteWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'confirmation': {
        'is_open': false,
        'title': '',
        'body': null,
        'on_confirm': null,
        'errors': [],
      },
      'is_processing': false,
      'is_editor_open': false,
    };
  }

  render() {
    const note = this.props.note;
    const noteId = getNested(note, ['id'], '');
    const noteTitle = getNested(note, ['title'], '');
    const noteHtml = getNested(note, ['note_html'], '');

    return (
      <React.Fragment>
        <Card className={`note_widget main-card mb-3`}>
          <CardBody>
            <UncontrolledAccordion stayOpen>
              <AccordionItem>
                <AccordionHeader targetId="1">
                  <CardTitle>{noteTitle}</CardTitle>
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  <div className="note_html" dangerouslySetInnerHTML={{__html: noteHtml}}></div>
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>

            <div className="controls">
              <MyButton outline className="mb-2 me-2 border-0 btn-transition" color="secondary"
                        onClick={this.onClickEdit}>
                <FontAwesomeIcon icon={faEdit}/>
              </MyButton>
              <MyButton outline className="mb-2 me-2 border-0 btn-transition" color="danger"
                        onClick={this.onClickDelete}>
                <FontAwesomeIcon icon={faRemove}/>
              </MyButton>
            </div>
          </CardBody>
        </Card>

        {this.renderConfirmation()}

        <NoteEditor
          noteId={noteId}
          isOpen={this.state['is_editor_open']}
          onSave={this.onSaveNote}
          onClose={() => {
            this.setState({
              'is_editor_open': false,
            });
          }}
        />
      </React.Fragment>
    );
  }

  onClickEdit = (evt) => {
    evt.stopPropagation();

    this.setState({
      'is_editor_open': true,
    });
  }

  onClickDelete = (evt) => {
    evt.stopPropagation();

    this.setState({
      'confirmation': {
        'is_open': true,
        'title': '',
        'body': tran(`Do you want to delete this note?`),
        'on_confirm': this.deleteNote,
      }
    });
  }

  deleteNote = () => {
    const noteId = getNested(this.props.note, ['id'], 0);
    this.setState({
      'is_processing': true,
    });
    ServerCommunication.executeCommand('notes/delete_note', {'note': {'id': noteId}}, data => {
      this.closeConfirmation();
      if (this.props.onUpdate) {
        this.props.onUpdate(getNested(data, ['note'], {}));
      }
    }, (errors, data) => {
      const confirmation = getNested(this.state, ['confirmation'], {});
      confirmation['errors'] = errors;
      this.setState({
        'confirmation': confirmation,
      });
    });
  }

  onSaveNote = () => {
    this.closeConfirmation();
    if (this.props.onUpdate) {
      this.props.onUpdate(this.props.note);
    }
  }

  renderConfirmation = () => {
    const confirmation = this.state['confirmation'];
    const isDisabled = this.state['is_processing'];
    const errors = getNested(this.state['confirmation'], ['errors'], []);
    return (
      <Modal
        isOpen={confirmation['is_open']}
        toggle={this.closeConfirmation}
      >
        <ModalHeader>{confirmation['title']}</ModalHeader>
        <ModalBody>{confirmation['body']}</ModalBody>
        <ModalFooter>
          <MyButton disabled={isDisabled} color="danger" onClick={confirmation['on_confirm']}>{tran('Yes')}</MyButton>
          <MyButton disabled={isDisabled} onClick={this.closeConfirmation}>{tran('No')}</MyButton>
        </ModalFooter>

        {errors.length ? (
          <ModalFooter style={{justifyContent: 'flex-start'}}>
            <Errors errors={this.state['confirmation']['errors']} />
          </ModalFooter>
        ) : null}
      </Modal>
    );
  }

  closeConfirmation = () => {
    this.setState({
      'is_processing': false,
      'confirmation': {
        'is_open': false,
      },
    });
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteWidget);
