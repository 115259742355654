import React from 'react';
import {Requester} from "../base/Requester";
import {MyButton} from "../base/MyButton";
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {getNested} from "../../util";
import {setSelectedPet} from "../../reducers/PetOptions";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import NoteEditor from "./NoteEditor";
import NoteWidget from "./NoteWidget";
import {tran} from "../../i18n_translations/translate";


class NotesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'is_editor_open': false,
      'request_id': 0,
    };
  }

  render() {
    if (this.props.selectedPet === null) return <Redirect to={'/pets/list'} />

    const selectedPet = this.props.selectedPet;

    return (
      <React.Fragment>
        {this.renderControls()}

        <Requester
          command="notes/get_list"
          params={{
            'pet_id': getNested(selectedPet, ['id'], 0),
          }}
          requestId={this.state['request_id']}
          renderData={data => {
            const records = getNested(data, ['records'], []);
            return (
              <React.Fragment>
                <Row>
                  {
                    records.map((record, idx) => {
                    return (
                      <Col key={`pet_${idx}`} md={12}>
                        <NoteWidget note={record} onUpdate={this.onUpdateNote} />
                      </Col>
                    );
                  })
                  }
                </Row>
              </React.Fragment>
            );
          }}
        />
      </React.Fragment>
    );
  }

  onUpdateNote = (task) => {
    this.setState({
      'request_id': this.state['request_id'] + 1,
    });
  }

  renderControls() {
    return (
      <React.Fragment>
        <div>
          {/*<Card className="main-card mb-3">*/}
          {/*  <CardBody>*/}
          <MyButton color="primary" onClick={this.onClickAddNote}>{tran('New note')}...</MyButton>
          {/*</CardBody>*/}
          {/*</Card>*/}
        </div>

        <NoteEditor
          noteId={0}
          isOpen={this.state['is_editor_open']}
          onSave={this.onSaveNote}
          onClose={() => {
            this.setState({
              'is_editor_open': false,
            });
          }}
        />
      </React.Fragment>
    );
  }

  onSaveNote = (task) => {
    this.setState({
      'is_editor_open': false,
      'request_id': this.state['request_id'] + 1,
    });
  }

  onClickAddNote = () => {
    this.setState({
      'is_editor_open': true,
      'task_id': 0,
    });
  }
}

const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({
  setSelectedPet: selectedPet => dispatch(setSelectedPet(selectedPet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesList);
