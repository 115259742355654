import React from 'react';
import {getNested} from "../../util";
import {tran} from "../../i18n_translations/translate";


/**
 * Props:
 * pet: object.
 * label: string.
 * before: any.
 */
export class PetAge extends React.Component {
  render() {
    const pet = this.props.pet;
    const birthYear = getNested(pet, ['data', 'birth', 'year'], null);
    const birthMonth = getNested(pet, ['data', 'birth', 'month'], null);
    const birthDay = getNested(pet, ['data', 'birth', 'day'], null);

    if (!birthYear) return <span>&nbsp;</span>;

    let mainBlock = null;
    if (birthYear && !birthMonth) {
      mainBlock = this.renderYear();
    } else if (birthYear && birthMonth && !birthDay) {
      mainBlock = this.renderMonth();
    } else {
      mainBlock = this.renderFull();
    }

    return (
      <React.Fragment>
        {this.props.before}
        {this.props.label ? <span>{this.props.label}: </span> : null}
        {mainBlock}
      </React.Fragment>
    );
  }

  renderYear() {
    const birthYear = getNested(this.props.pet, ['data', 'birth', 'year'], 0);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const numOfYears = currentYear - birthYear;

    if (numOfYears <= 0) {
      return <span>{tran('less than a year')}</span>;
    }

    return <span>{tran('about')} {numOfYears} {tran('years', {count: numOfYears})}</span>;
  }

  renderMonth() {
    const birthYear = getNested(this.props.pet, ['data', 'birth', 'year'], null);
    const birthMonth = getNested(this.props.pet, ['data', 'birth', 'month'], null);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const numOfAllMonths = (currentYear - birthYear) * 12 - birthMonth + currentMonth;
    const numOfYears = Math.floor(numOfAllMonths / 12);
    const numOfMonths = numOfAllMonths - (numOfYears * 12);

    const parts = [
      numOfYears > 0 ? (
        <span>{numOfYears} {tran('years', {count: numOfYears})} <span> </span></span>
      ) : null,
      numOfMonths > 0 ? (
        <span>{numOfMonths} {tran('months', {count: numOfMonths})} <span> </span></span>
      ) : null,
    ]

    return (
      <span>
        {tran('about')} <span> </span>
        {parts}
      </span>
    );
  }

  renderFull() {
    const MS_IN_DAY = 1000*60*60*24;
    const birthYear = getNested(this.props.pet, ['data', 'birth', 'year'], null);
    const birthMonth = getNested(this.props.pet, ['data', 'birth', 'month'], null);
    const birthDay = getNested(this.props.pet, ['data', 'birth', 'day'], null);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();

    let numOfAllMonths = (currentYear - birthYear) * 12 - birthMonth + currentMonth;
    if (currentDay < birthDay) numOfAllMonths -= 1;

    const numOfYears = Math.floor(numOfAllMonths / 12);
    const numOfMonths = numOfAllMonths - (numOfYears * 12);

    let numOfDays;
    if (currentDay < birthDay) {
      numOfDays = (currentDate - (new Date(currentYear, currentMonth - 1 - 1, birthDay))) / MS_IN_DAY;
    } else {
      numOfDays = currentDay - birthDay;
    }

    const parts = [
      numOfYears > 0 ? (
        <span>{numOfYears} {tran('years', {count: numOfYears})} <span> </span></span>
      ) : null,
      numOfMonths > 0 ? (
        <span>{numOfMonths} {tran('months', {count: numOfMonths})} <span> </span></span>
      ) : null,
      numOfDays > 0 ? (
        <span>{numOfDays} {tran('days', {count: numOfDays})} <span> </span></span>
      ) : null,
    ]

    return (
      <span>
        {parts}
      </span>
    );
  }
}
