import React from 'react';
import {Requester} from "../base/Requester";
import {MyButton} from "../base/MyButton";
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {PetEditor} from "./PetEditor";
import {getNested} from "../../util";
import PetWidget from "./PetWidget";
import {tran} from "../../i18n_translations/translate";


export class PetsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'is_editor_open': false,
      'request_id': 0,
    };
  }

  render() {
    return (
      <React.Fragment>
        {this.renderControls()}

        <Requester
          command="pets/get_list"
          requestId={this.state['request_id']}
          renderData={data => {
            console.log(data);
            const records = getNested(data, ['records'], []);
            return (
              <React.Fragment>
                <Row>
                  {records.map((record, idx) => {
                    return (
                      <Col key={`pet_${idx}`} md={4}>
                        <PetWidget pet={record} onUpdate={this.onUpdatePet} />
                      </Col>
                    );
                  })}
                </Row>
              </React.Fragment>
            );
          }}
        />
      </React.Fragment>
    );
  }

  onUpdatePet = (pet) => {
    this.setState({
      'request_id': this.state['request_id'] + 1,
    });
  }

  renderControls() {
    return (
      <React.Fragment>
        <div>
          {/*<Card className="main-card mb-3">*/}
          {/*  <CardBody>*/}
          <MyButton color="primary" onClick={this.onClickAddPet}>{tran('Add a pet')}...</MyButton>
          {/*</CardBody>*/}
          {/*</Card>*/}
        </div>

        <PetEditor
          petId={0}
          isOpen={this.state['is_editor_open']}
          onSave={this.onSavePet}
          onClose={() => {
            this.setState({
              'is_editor_open': false,
            });
          }}
        />
      </React.Fragment>
    );
  }

  onSavePet = (pet) => {
    this.setState({
      'is_editor_open': false,
      'request_id': this.state['request_id'] + 1,
    });
  }

  onClickAddPet = () => {
    this.setState({
      'is_editor_open': true,
      'pet_id': 0,
    });
  }
}
