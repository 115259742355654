import React from 'react';
import {Requester} from "../base/Requester";
import {MyButton} from "../base/MyButton";
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {getNested} from "../../util";
import TaskEditor from "./TaskEditor";
import PetWidget from "../pets/PetWidget";
import TaskWidget from "./TaskWidget";
import {setSelectedPet} from "../../reducers/PetOptions";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {tran} from "../../i18n_translations/translate";


class TasksList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'is_editor_open': false,
      'request_id': 0,
    };
  }

  render() {
    return (
      <React.Fragment>
        {this.renderControls()}

        <Requester
          command="task/get_list"
          params={{}}
          requestId={this.state['request_id']}
          renderData={data => {
            const records = getNested(data, ['records'], []);
            return (
              <React.Fragment>
                <Row>
                  {records.map((record, idx) => {
                    return (
                      <Col key={`pet_${idx}`} md={12}>
                        <TaskWidget task={record} onUpdate={this.onUpdateTask} />
                      </Col>
                    );
                  })}
                </Row>
              </React.Fragment>
            );
          }}
        />
      </React.Fragment>
    );
  }

  onUpdateTask = (task) => {
    this.setState({
      'request_id': this.state['request_id'] + 1,
    });
  }

  renderControls() {
    return (
      <React.Fragment>
        <div>
          {/*<Card className="main-card mb-3">*/}
          {/*  <CardBody>*/}
          <MyButton color="primary" onClick={this.onClickAddTask}>{tran('New task')}...</MyButton>
          {/*</CardBody>*/}
          {/*</Card>*/}
        </div>

        <TaskEditor
          taskId={0}
          isOpen={this.state['is_editor_open']}
          onSave={this.onSaveTask}
          onClose={() => {
            this.setState({
              'is_editor_open': false,
            });
          }}
        />
      </React.Fragment>
    );
  }

  onSaveTask = (task) => {
    this.setState({
      'is_editor_open': false,
      'request_id': this.state['request_id'] + 1,
    });
  }

  onClickAddTask = () => {
    this.setState({
      'is_editor_open': true,
      'task_id': 0,
    });
  }
}

const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({
  setSelectedPet: selectedPet => dispatch(setSelectedPet(selectedPet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksList);
