import React from 'react';
import {Button} from "reactstrap";
import {MyFormField} from "./MyFormField";


export function MyButton(props) {
  const {onChangeField, ...rest} = props;
  return (
    <MyFormField
      {...rest}
      render={innerProps => {
        return <Button {...innerProps} />
      }}
    />
  );
}
