import React from 'react';
import {Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {MyButton} from "../base/MyButton";
import {MyForm} from "../forms/MyForm";
import {TextInput} from "../forms/TextInput";
import {ServerCommunication} from "../../ServerCommunication";
import {getNested, setNested} from "../../util";
import {ISO_DATE_FORMAT, DateInput} from "../forms/DateInput";
import moment from "moment";
import {connect} from "react-redux";
import {DateTimeInput} from "../forms/DateTimeInput";
import {NumericInput} from "../forms/NumericInput";
import {tran} from "../../i18n_translations/translate";
import {SelectInput} from "../forms/SelectInput";
import {BooleanInput} from "../forms/BooleanInput";
import {MyFormGroup} from "../forms/MyFormGroup";


/**
 * Props:
 * petTaskId: integer.
 * isOpen: boolean.
 * onSave: function(task: object).
 * onClose: function().
 */
class PetTaskEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'state': '',
      'request_id': 0,
      'errors': [],
      // 'errors': [{'message': 'Error 001'}],

      'pet_task': {
        // 'first_day': moment().format(ISO_DATE_FORMAT),
        'times_per_period': 1,
        // // 'first_daye': moment().format(DATE_FORMAT),
        // // 'first_day': '2023-05-06',
      },
      'metadata': {},
    };

    // console.log(moment().format(DateInput.DATE_FORMAT));
  }

  render() {
    const isProcessing = this.state['state'] === 'processing';
    const editorTitle = this.props.petTaskId === 0 ? tran('New task') : tran('Task');

    const timesPerPeriod = getNested(this.state['pet_task'], this.getFieldPath('times_per_period'), 1);

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          // fullscreen={true}
          toggle={this.onClose}
        >
          <ModalHeader toggle={this.onClose}>{editorTitle}</ModalHeader>
          <ModalBody>
            <MyForm
              disabled={isProcessing}
              onChange={this.onFormChange}
              values={{
                'name': getNested(this.state['pet_task'], this.getFieldPath('name'), ''),
                'description': getNested(this.state['pet_task'], this.getFieldPath('description'), ''),
                'times_per_period': timesPerPeriod,
                'period': getNested(this.state['pet_task'], this.getFieldPath('period'), ''),
                'first_day': getNested(this.state['pet_task'], this.getFieldPath('first_day'), null),
                'last_day': getNested(this.state['pet_task'], this.getFieldPath('last_day'), null),
                'is_everlasting': getNested(this.state['pet_task'], this.getFieldPath('is_everlasting'), false),
              }}
              errors={this.state['errors']}
              render={(props) => {
                const isEverlasting = getNested(props, ['values', 'is_everlasting'], false);
                return (
                  <React.Fragment>
                    {/*<FormGroup>*/}
                    {/*  <SelectInput*/}
                    {/*    label={tran('Task')}*/}
                    {/*    name="task_id"*/}
                    {/*    isClearable*/}
                    {/*    options={speciesOptions}*/}
                    {/*    {...props}*/}
                    {/*  />*/}
                    {/*</FormGroup>*/}
                    <FormGroup>
                      <TextInput label={tran('Name')} name="name" {...props} />
                    </FormGroup>
                    <FormGroup>
                      <TextInput label={tran('Description')} name="description" type="textarea" {...props} />
                    </FormGroup>
                    <MyFormGroup>
                      <NumericInput isInline name="times_per_period" min={1} integer {...props} />
                      <span> {tran('times', {count: timesPerPeriod})} {tran('per day')}</span>
                      {/*<span> times per </span>*/}
                      {/*<SelectInput*/}
                      {/*  isInline*/}
                      {/*  name="period"*/}
                      {/*  options={[*/}
                      {/*    {label: 'day', value: 'day'},*/}
                      {/*    {label: 'week', value: 'week'},*/}
                      {/*    {label: 'month', value: 'month'},*/}
                      {/*    {label: 'year', value: 'year'},*/}
                      {/*  ]}*/}
                      {/*  {...props}*/}
                      {/*/>*/}
                    </MyFormGroup>
                    <FormGroup>
                      <BooleanInput title={tran('Everlasting task')} name="is_everlasting" {...props} />
                    </FormGroup>
                    <MyFormGroup hidden={isEverlasting}>
                      <DateTimeInput
                        name="first_day"
                        label={tran('Starts on')}
                        isClearable
                        {...props}
                      />
                      <DateTimeInput
                        name="last_day"
                        label={tran('Ends on')}
                        isClearable
                        {...props}
                      />
                    </MyFormGroup>
                  </React.Fragment>
                );
              }}
            />
          </ModalBody>
          <ModalFooter>
            <MyButton color="primary" onClick={this.savePetTask} disabled={isProcessing}>{tran('Save')}</MyButton>
            <MyButton onClick={this.onClose} disabled={isProcessing}>{tran('Cancel')}</MyButton>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }

  onFormChange = (name, value) => {
    const task = this.state['pet_task'];
    const path = this.getFieldPath(name);
    setNested(task, path, value);
    // task[name] = value;
    this.setState({
      'pet_task': task,
    });
  }

  getFieldPath(fieldName) {
    return getNested({
      'name': ['name'],
      'description': ['description'],
      'times_per_period': ['data', 'times_per_period'],
      'period': ['data', 'period'],
      'first_day': ['data', 'first_day'],
      'last_day': ['data', 'last_day'],
    }, [fieldName], [fieldName]);
  }

  loadPetTask() {
    this.setState({
      'state': 'processing',
      'errors': [],
    });

    ServerCommunication.executeCommand('pet_task/load_task', {'pet_task': {'id': this.props.petTaskId}}, (data, requestId) => {
      const petTask = getNested(data, ['pet_task'], {});
      this.setState({
        'state': '',
        'pet_task': petTask,
        'metadata': getNested(data, ['metadata'], {}),
      });
    }, (errors, data, requestId) => {
      this.setState({
        'state': '',
        'errors': errors,
      });
    });
  }

  savePetTask = () => {
    this.setState({
      'state': 'processing',
      'errors': [],
    });

    const params = {
      'pet_task': this.state['pet_task'],
      'pet_id': getNested(this.props.selectedPet, ['id'], 0),
    };
    ServerCommunication.executeCommand('pet_task/save_pet_task', params, (data, requestId) => {
      this.setState({
        'state': '',
      });
      this.onSaveTask();
    }, (errors, data, requestId) => {
      this.setState({
        'state': '',
        'errors': errors,
      });
    });
  }

  onSaveTask = () => {
    if (!this.props.onSave) return;
    this.props.onSave();
  }

  onClose = () => {
    if (!this.props.onClose) return;
    this.props.onClose();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.loadPetTask();
    }
  }
}

const mapStateToProps = state => ({
  selectedPet: state.PetOptions.selectedPet,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PetTaskEditor);
